import React, { memo, useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { A, Avatar, Col, Div, message, Popover, Row, Spacer } from 'atoms';
import { Avatar as AndtAvatar, Button, MenuProps, Dropdown, Tag} from 'antd'

import { ChatMessage } from 'types';
import { useMemo } from 'react';
import { parseDate } from 'utils/Datetime';
import { FaEllipsisV, FaHeart, FaSHeart, FaThumbtack, FaTrashAlt } from 'icons';
import { useMutation } from '@apollo/client';
import { DELETE_CHAT_MESSAGE_MUTATION, PINNED_CHAT_MESSAGE, PUBLIC_SHOW_CHAT_CONTACT_QUERY, TOGGLE_CHAT_MESSAGE_LIKE_MUTATION, TOGGLE_CHAT_MESSAGE_PIN_MUTATION } from 'graphql-api';
import { TRUE } from 'consts';
import { DeleteCta } from 'molecules';
import UserProfile from 'components/UserProfile';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentAlt, faHeart } from '@fortawesome/free-regular-svg-icons';
import { FanPageContext, PublicPageContext } from 'apps/PublicPage/context';
import { useRouteMatch, NavLink } from 'react-router-dom';
import { createPortal } from 'react-dom';
import { LazyUserProfile, MessageText } from './common';

const MessageBody = styled.div``;
const MessageHeader = styled.div`
  margin-bottom: 4px;
`;

const CreatedAt = styled.div`
  font-size: 13px;
  color: var(--color-grey-lighter);
  white-space: pre;
`;

const AvatarContainer = styled.div`
  padding-top: 6px;
`;

const LikeUserItem = styled.div`
  margin-bottom: 3px;
  &:last-child {
    margin-bottom: 0;
  }
`;
const ControlButton = styled(Button)`
  color: grey;
  &&& > .ant-btn-icon {
    vertical-align: middle;
    margin-inline-end: 4px;
  }
`
ControlButton.defaultProps = { type: 'link' };

type Props = {
  message: ChatMessage
  type?: 'pinned' | 'default'
}

const ChatMessageItem = (props: Props) => {
  // @ts-ignore
  const { me, isLoggedIn, openAuthModal } = useContext(PublicPageContext);
  const { show } = useContext(FanPageContext);
  const viewerIsAdmin = show.viewerIsAdmin;
  const { message: chatMessage, type = 'default' } = props;
  const { url } = useRouteMatch();

  const { id, content, user, createdAt, liked, likesBreakdown, roleLabel, __typename } = chatMessage;
  const theCreatedAt = useMemo(() => {
    const date = new Date(createdAt);
    const parsed = parseDate(date);;
    return `${parsed.hh}:${parsed.mm} ${parsed.apm}`;
  }, [createdAt]);
  const canEditMessage = viewerIsAdmin || me?.id === user.id;
  const theContent = useMemo(() => {
    // @ts-ignore
    
    return content.replaceAll(/@\w[^\s]+/g, (match, p1) => `<span class="mention_user">${match}</span>`).replaceAll(/@{([^|]+)\|(\d+)}/g, (_, name, id) => {
      return `<span class="mention_user MentionUser" contact-id="${id}" username="${name}"></span>`;
    })
  }, [content]);
  const textRef = useRef<HTMLDivElement>();
  const [mentionUserRef, setMentionUserRef] = useState<NodeListOf<HTMLDivElement>>();

  const [triggerDelete, { loading }] = useMutation(DELETE_CHAT_MESSAGE_MUTATION, {
    variables: { chatMessageId: id },
    onCompleted({ deleteChatMessage: { success } }) {
      if (success === TRUE) {
        message.success({ content: "Message Deleted" });
      }
    }
  });
  const [triggerToggleLike] = useMutation(TOGGLE_CHAT_MESSAGE_LIKE_MUTATION, {
    variables: { chatMessageId: id },
    onCompleted({ toggleChatMessageLike: { success } }) {
    }
  });

  const [triggerTogglePin] = useMutation(TOGGLE_CHAT_MESSAGE_PIN_MUTATION, {
    variables: { chatMessageId: id },
    awaitRefetchQueries: true,
    refetchQueries: [{ query: PINNED_CHAT_MESSAGE, variables: { showId: show.id } }],
    onCompleted({ toggleChatMessagePin: { success } }) {
      if (success === TRUE) {
      }
    }
  });

  const handleToggleLike = () => {
    if (!isLoggedIn) {
      openAuthModal();
      return false;
    }

    triggerToggleLike({
      optimisticResponse: {
        toggleChatMessageLike: {
          success: true,
          chatMessage: {
            __typename,
            id,
            liked: !liked
          }
        }
      }
    });
  }
  const handleTogglePin = () => {
    triggerTogglePin()
  }
  const handleMessageTextClick = (e) => {
    if (e.detail === 2) {
      handleToggleLike();
    }
  }
  const handleReply = () => {

  }
  const controlItems: MenuProps['items'] = [
    {
      key: 'like',
      icon: <FaSHeart verticalMiddle color={liked ? 'primary' : 'inherit'} />,
      label: 'like',
      onClick: handleToggleLike
    },
    ...(viewerIsAdmin ? [
      {
        key: 'pin',
        label: chatMessage.pinned ? 'unpin' : 'pin',
        icon: <FaThumbtack verticalMiddle color={chatMessage.pinned ? 'primary' : 'inherit'} />,
        onClick: handleTogglePin
      },
    ] : []),
    ...(canEditMessage ? [
      {
        key: 'delete',
        label: <DeleteCta onDelete={triggerDelete} loading={loading} closeAfterDelete={true}>delete</DeleteCta>,
        icon: <FaTrashAlt verticalMiddle />,
      }
    ]: [])
  ];
  const renderRoleTag = () => {
    switch (roleLabel) {
      case 'creator':
        return <Tag color='green'>creator</Tag>;
      case 'admin':
        return <Tag color='green'>admin</Tag>
      case 'member':
        return <Tag color='red'>subscriber</Tag>
      default:
        return null;
    }
  }

  useEffect(() => {
    setMentionUserRef(textRef.current.querySelectorAll('.MentionUser'));
  }, []);
  return (
    <div className='ChatMessageItem'>
      <Row gutter={12}>
        {
          type === 'default' && (
            <Col>
              <Popover content={<UserProfile user={user} />} simple trigger={['click']} placement='topLeft'>
                <AvatarContainer>
                  <Avatar src={user.avatarUrl} width="35px" radius='5px' />
                </AvatarContainer>
              </Popover>
            </Col>
          )
        }
        <Col flex="1 0 0" minZero>
          <MessageBody>
            <MessageHeader>
              <Row align='middle' gutter={12}
                style={{
                  flexWrap: type === 'default' ? 'nowrap' : 'wrap',
                  ...(type === 'pinned' ? { width: 'calc(100% - 100px)' } : {}),
                }}
              >
                <Col minZero css='flex-shrink: 1; overflow: hidden; text-overflow: ellipsis; white-space: pre;'>
                  <Popover content={<UserProfile user={user} />} simple trigger={['click']} placement='topLeft'>
                    <A>
                      {user.displayName}
                    </A>
                  </Popover>
                </Col>
                {
                  roleLabel !== 'fan' && (
                    <Col>{renderRoleTag()}</Col>
                  )
                }
                <Col>
                  <CreatedAt>{theCreatedAt}</CreatedAt>
                </Col>
              </Row>
            </MessageHeader>
            {
              type === 'default' ? (
                <MessageText ref={textRef} onClick={handleMessageTextClick} dangerouslySetInnerHTML={{ __html: theContent }}></MessageText>
              ) : (
                <MessageText ref={textRef}>
                  <AndtAvatar src={user.avatarUrl} size={100} style={{ float: 'right', marginTop: '-90px', marginRight: '-10px', border: '5px solid white', boxShadow: '0 0 5px 4px rgba(0,0,0,0.06)', backgroundColor: 'white' }} />
                  <span dangerouslySetInnerHTML={{__html: theContent}}></span>
                </MessageText>
              )
            }
            <Div className='mt-1' style={{marginLeft: `-7px`}}>
              <Row align='middle'>
                <Col>
                  {
                    likesBreakdown.length > 0 ? (
                      <Popover
                        placement='rightTop'
                        content={(
                          <Div>
                            {
                              likesBreakdown.map(likeUser => (
                                <LikeUserItem key={likeUser.id}>{likeUser.displayName}</LikeUserItem>
                              ))
                            }
                          </Div>
                        )}
                      >
                        <ControlButton
                          onClick={handleToggleLike}
                          icon={liked ? <FaSHeart color="primary" verticalMiddle /> : <FontAwesomeIcon icon={faHeart} />} size='small'
                        >
                          {
                            likesBreakdown.length
                          }
                        </ControlButton>
                      </Popover>
                    ) : (
                      <ControlButton
                        onClick={handleToggleLike}
                        icon={<FontAwesomeIcon icon={faHeart} />} size='small'
                      >{' '}</ControlButton>
                    )
                  }
                </Col>
                <Col>
                  <NavLink to={`${url}?id=${chatMessage.id}`} style={{display: 'block'}}>
                    <ControlButton onClick={handleReply} icon={<FontAwesomeIcon icon={faCommentAlt}/>} size='small'>{chatMessage.replyCount > 0 ? `${chatMessage.replyCount} replies` : ''}</ControlButton>
                  </NavLink>
                </Col>
                <Spacer />
                {
                  !!me && (
                    <Col>
                      <Dropdown trigger={["click"]} menu={{items: controlItems}}>
                        <Button icon={<FaEllipsisV />} type="text" />
                      </Dropdown>
                    </Col>
                  )
                }
              </Row>
            </Div>
          </MessageBody>
        </Col>
      </Row>
      {/* this is for profile popup for user mentioned in message text */}
      {mentionUserRef && [...mentionUserRef].map((el) => (
        createPortal(
          <Popover content={<LazyUserProfile contactId={el.getAttribute('contact-id')} />} simple trigger={['click']} placement='topLeft'>
            @{el.getAttribute('username')}
          </Popover>,
          el
        )
      ))}
    </div>
  )
}

export default memo(ChatMessageItem);
