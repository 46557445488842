import React, { useContext, useEffect, useRef, useState } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBackward, faForward, faPause, faRedo, faUndo } from '@fortawesome/free-solid-svg-icons';
import { A, Card, CardInner, CardTitle, Div, ErrorCard, Link, SkeletonCard, Span, HtmlEllipsis, Row, Col, Image } from "atoms";
import {
  RecordRow,
  VisualizationContainer,
  RecordVisualization,
  RecordCTAContainer,
  RecordCTAButton,
} from 'libraries/Audio/components/AudioRecord/components/common';
import { INITIAL, PLAYING } from 'libraries/Audio/const';
import { Button, Dropdown, Flex, Slider } from 'antd';
import { FaPause, FaPlay } from 'icons';
import { useHowler } from 'libraries/howler';

export const PauseButton = (props) => (
  <RecordCTAButton type="primary" icon={<FontAwesomeIcon icon={faPause} />} {...props} />
);

const PlayButton = styled(Button)`
  &.ant-btn.ant-btn-icon-only {
    width: 40px;
    height: 40px;
    border-radius: 5px;
    border-width: 0px;
    .ant-btn-icon {
      svg {
        color: var(--color-primary);
      }
    }
  }
`;

const StyledPreviewPlayer = styled.div``;

const AudioPlayContainer = styled.div`
  width: 100%;
 
`;

const AudioDuration = styled.div`
  color: white;
  ${(props) =>
    props.singleRow &&
    `
        width: auto;
        text-align: right;
    `}
`;
const PodSlider = styled(Slider)`
  .ant-slider-track {
    background-color: ${(props) => `${props.theme.colors.primary}`};
  }
  .ant-slider-handle {
    background-color: var(--color-primary);
    :after {
      background-color: var(--color-primary);
      box-shadow: 0 0 0 2px white;
    }
  }
  &.ant-slider .ant-slider-rail {
    background-color: white;
  }
  &:hover {
    .ant-slider-track {
      background-color: ${(props) => `${props.theme.colors.primary}`};
    }
    .ant-slider-handle:not(.ant-tooltip-open) {
      background-color: var(--color-primary-hover);
      :after {
        box-shadow: 0 0 0 2px var(--color-primary-hover);
      }
    }
  }
`;

const SControlButton = styled(Button)`
  && {
    min-width: 60px;
    height: 30px !important;
    &:hover {
      color: white;
    }
  }
`;

const rateOptions = [0.8, 1, 1.2, 1.5, 1.8, 2];

const PreviewPlayerDark = ({ src, duration, simple = false }) => {
  const { isMobile } = useContext(ThemeContext);
  const [playRate, setPlayRate] = useState(1);
  const { playing, paused, loaded, loading, play, idle, pause, seekPercent, seekTo, tmm, tss, mm, ss, forward, backward } = useHowler({
    src,
    duration,
    rate: playRate
  });

  const handlePlayClick = () => {
    play();
  };
  const handlePauseClick = () => {
    pause()
  };
  const onSliderChange = (v) => {
    seekTo((duration * v) / 100);
  }
  return (
    <StyledPreviewPlayer>
      <RecordRow css={!simple ? `padding: 15px 15px 15px 15px; border: 1px solid var(--color-border); border-radius: 5px;` : ''}>
        <VisualizationContainer css={`margin-right: 0;`}>
          <RecordVisualization css={`border: 0;`}>
            <AudioPlayContainer>
              <div style={{padding: isMobile ? '0 9px' : ''}}>
                 <div style={{width:'100%'}}>
                    <PodSlider
                      style={{display:'flex',justifyContent:'center'}}
                      defaultValue={0}
                      value={seekPercent}
                      step={0.01}
                      onChange={onSliderChange}
                      tooltip={{ open: false }}
                    />
                  </div>
                  <div>
                    <AudioDuration
                      singleRow={true}
                      style={{ display: "flex", justifyContent: "space-between" ,fontSize:'10px' ,marginTop:isMobile ? '0':'-9px',marginBottom:isMobile ? '0':'5px'}}>
                      <div style={{marginLeft:isMobile ? '0':'8px', fontSize: '12px' }}>{idle ? '00:00' : `${mm}:${ss}`}</div>
                      <div style={{marginRight:isMobile ? '0':'4px',marginRight:isMobile ? '10px':'8px', fontSize: '12px' }}>{tmm}:{tss}</div>  
                    </AudioDuration>
                  </div>

                <Flex>
                  <div style={{ flex: '1 0 0' }}>
                    {
                      !isMobile && (
                          <Row style={{display:'flex',justifyContent:'space-between'}}>
                            <Col style={{display:'flex',justifyContent:'flex-start'}}>
                              <SControlButton onClick={() => backward(15)} shape='round' size='small' style={{marginTop:'9px', marginLeft:isMobile ? '0':'0', fontSize: '11px', border: 'none', boxShadow: 'none'}}>
                                <Flex gap={6} align='center'>
                                  <span>-15</span>
                                  <FontAwesomeIcon icon={faUndo} />
                                </Flex>
                              </SControlButton>
                            </Col>
                            <Row style={{display:'flex',justifyContent:'center',gap:'5px'}}>
                              <Col>
                                <RecordCTAContainer css={`width: 40px;
                                  align-items: center;`}style={{marginBottom:'10px'}}>
                                  {
                                    !playing && (
                                      <PlayButton
                                        icon={<FaPlay />}
                                        onClick={() => handlePlayClick()}
                                        loading={loading}
                                      />
                                    )
                                  }
                                  {
                                    playing && (
                                      <PlayButton
                                        icon={<FaPause />}
                                        onClick={() => handlePauseClick()}
                                      />
                                    )
                                  }
                                </RecordCTAContainer>
                              </Col>
                              <Col>
                                <Dropdown
                                  trigger={['click']}
                                  menu={{
                                    selectedKeys: [`rate_${playRate}`],
                                    items: rateOptions.map((rate) => ({
                                      key: `rate_${rate}`,
                                      label: rate,
                                      style: {
                                        padding: '3px 12px',
                                      },
                                      className: 'hover:text-primary',
                                      onClick: () => setPlayRate(rate)
                                    }))
                                  }}
                                >
                                  <SControlButton type='text' shape='round' size='small' style={{color:'white',marginLeft:'0',marginTop:'10px', minWidth: 'unset'}}>{playRate}×</SControlButton>
                                </Dropdown>
                              </Col>
                            </Row>
                              <Col style={{display:'flex',justifyContent:'flex-end'}}>
                                <SControlButton onClick={() => forward(15)} shape='round' size='small' style={{marginTop:'9px',marginRight:isMobile ? '0':'0', fontSize: '11px',  border: 'none', boxShadow: 'none'}}>
                                  <Flex gap={6} align='center'>
                                    <FontAwesomeIcon icon={faRedo} />
                                    <span>+15</span>
                                  </Flex>
                                </SControlButton>
                              </Col>
                          </Row>
                      )
                    }
                  </div>
                </Flex>
              </div>
            </AudioPlayContainer>
          </RecordVisualization>
        </VisualizationContainer>
      </RecordRow>
      {
        isMobile && (
          <div>
            <Flex align='center' justify={'space-between'}>
              <Col>
                <SControlButton onClick={() => backward(15)} shape='round' size='small' style={{border: 'none', boxShadow: 'none'}}>
                  <Flex gap={6} align='center'>
                    <span style={{fontSize: '10px'}}>-15</span>
                    <FontAwesomeIcon icon={faUndo} />
                  </Flex>
                </SControlButton>
              </Col>
              <Row>
                <Col>
                  <RecordCTAContainer css={`width: 40px;
                    align-items: center;`}style={{marginBottom:'10px',marginLeft:'30px'}}>
                    {
                      !playing && (
                        <PlayButton
                          icon={<FaPlay />}
                          onClick={() => handlePlayClick()}
                          loading={loading}
                        />
                      )
                    }
                    {
                      playing && (
                        <PlayButton
                          icon={<FaPause />}
                          onClick={() => handlePauseClick()}
                        />
                      )
                    }
                  </RecordCTAContainer>
                </Col>
                <Dropdown
                  trigger={['click']}
                  menu={{
                    selectedKeys: [`rate_${playRate}`],
                    items: rateOptions.map((rate) => ({
                      key: `rate_${rate}`,
                      label: rate,
                      style: {
                        padding: '3px 12px',
                      },
                      className: 'hover:text-primary',
                      onClick: () => setPlayRate(rate)
                    }))
                  }}
                >
                  <SControlButton type='text' shape='round' size='small' style={{color:'white', marginTop:'10px',marginLeft:'0', minWidth: 'unset'}}>{playRate}×</SControlButton>
                </Dropdown>
              </Row>
              <Col style={{paddingLeft:'0px',paddingRight:'0px'}}>
                <SControlButton onClick={() => forward(15)} shape='round' size='small' style={{marginRight:'6px', border: 'none', boxShadow: 'none'}}>
                  <Flex gap={6} align='center' >
                    <FontAwesomeIcon icon={faRedo} />
                    <span style={{fontSize: '10px'}}>+15</span>
                  </Flex>
                </SControlButton>
              </Col>
            </Flex>
          </div>
        )
      }
    </StyledPreviewPlayer>
  );
};

export default PreviewPlayerDark;
