import React, { useContext } from 'react';
import { FanPageContext } from 'apps/PublicPage/context';
import ChatPage from 'apps/PublicPage/modules/chat/ChatPage';
import ChatPaywall from 'apps/PublicPage/modules/chat/ChatPaywall';

const Chat = () => {

  const { show } = useContext(FanPageContext);

  if (show.viewerHasChatAccess) {
    return (
      <ChatPage />
    )
  }
  if ( show.chatRoomForMembers ) {
    return <ChatPaywall />
  }
  // guest access chat page
  return <ChatPage />
}

export default Chat;
