import React, { useContext, useRef } from 'react';
import { useIntersectionObserver } from 'usehooks-ts';
import {
  PageContentRow,
  PageContentColumnLeft,
  PageContentColumnRight,
  SectionHeader,
  SectionTitle,
} from "apps/PublicPage/components/FanPage";
import { Drawer } from 'antd';
import { Card, CardInner, DrawerInner } from 'atoms';
import ChatMessageList from 'apps/PublicPage/modules/chat/ChatMessageList';
import ChatForm from 'apps/PublicPage/modules/chat/ChatForm';
import PinnedMesageList from 'apps/PublicPage/modules/chat/PinnedMessageList';
import { useURLQuery, useValueFalseDelayed } from 'hooks';
import { useHistory, useRouteMatch } from 'react-router-dom';
import ChatReplyForm from './ChatReplyForm';
import ChatReplyContent from './ChatReplyContent';
import { ThemeContext } from 'styled-components';
import { useQuery } from '@apollo/client';
import { PINNED_CHAT_MESSAGE } from 'graphql-api';
import { FanPageContext } from 'apps/PublicPage/context';

const ChatPage = () => {

  const { isDesktop } = useContext(ThemeContext);
  const { show } = useContext(FanPageContext);
  const history = useHistory();
  const { url } = useRouteMatch();
  const { id } = useURLQuery();
  const safeId = useValueFalseDelayed(id, 500);
  const { data: pinnedData, loading: pinFetching } = useQuery(PINNED_CHAT_MESSAGE, {
    variables: {
      showId: show.id
    }
  });

  const { isIntersecting, ref } = useIntersectionObserver({
    threshold: 1.0,
  })
  const titleRef = useRef<HTMLDivElement>();
  const replyTopRef = useRef<HTMLDivElement>();

  const closeReplyDrawer = () => {
    history.push(url);
  }
  const handleSubmitMessage = () => {
    titleRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest'
    })
  }
  const handleSubmit = () => {
    replyTopRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest'
    })
  }
  return (
    <PageContentRow>
      <PageContentColumnLeft>
        <SectionHeader>
          <SectionTitle ref={titleRef}>Messages</SectionTitle>
        </SectionHeader>
        <Card shadow simple>
          <CardInner>
            <ChatMessageList />
          </CardInner>
        </Card>
        <div
          style={{
            position: 'sticky',
            bottom: '-1px',
            paddingBottom: isIntersecting ? 0 : '1px',
            marginTop: '15px',
            ...(!isDesktop ? {margin: '15px -15px'} : {})
          }}
          ref={ref}
        >
          <Card shadow={!isIntersecting} simple style={{...(!isDesktop ? {borderRadius: 0}: {})}}>
            <CardInner>
              <ChatForm onSubmit={handleSubmitMessage} />
            </CardInner>
          </Card>
        </div>
      </PageContentColumnLeft>
      <PageContentColumnRight>
        {
          pinFetching || !!pinnedData.pinnedChatMessage && (
            <>
              <SectionHeader>
                <SectionTitle>Pinned Chat</SectionTitle>
              </SectionHeader>
              <PinnedMesageList />
            </>
          )
        }
      </PageContentColumnRight>
      <Drawer
        open={!!id}
        onClose={closeReplyDrawer}
        title='Replies'
        footer={<ChatReplyForm parentId={safeId} onSubmit={handleSubmit} />}
        width={700}
      >
        <ChatReplyContent parentId={safeId} ref={replyTopRef} />
      </Drawer>
    </PageContentRow>
  )
}

export default ChatPage;
