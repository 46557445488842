import React, { useMemo } from 'react';
import styled from "styled-components";
import {
  SkeletonCard,
  Row,
  Col
} from 'atoms';
import { formatFromIos } from "utils/Datetime";
import { sourceMap } from "micro-data";
import { useQuery } from "@apollo/client";
import { SHOW_FANS_PAYMENTS_QUERY } from "apps/Dashboard/graphql";

const SPaymentsTab = styled.div``;

const Amount = styled.div`
  ${(props) =>
  props.refund
    ? `
    color: var(--color-grey-lighter);
  `
    : `}
    color: var(--color-green);
  `};
`;

const SNone = styled.div`
  color: var(--color-content-grey);
`;

const PaymentsTab = (
  {userId}
) => {
  const {data, loading} = useQuery(SHOW_FANS_PAYMENTS_QUERY, {
    variables: {
      userId
    }
  });
  const showFansPayments = useMemo(() => data?.showFansPayments || [], [data]);
  const showFansPaymentsFormatted = useMemo(
    () =>
      showFansPayments.map((p) => {
        const {id, date, amountCents, user, refund, source} = p;
        return {
          key: id,
          id,
          dateFormatted: formatFromIos(date),
          date,
          amount: Math.round(amountCents / 100),
          userName: user.displayName,
          refund,
          user,
          source: sourceMap[source],
        };
      }),
    [showFansPayments]
  );

  if (loading) {
    return <SkeletonCard/>
  }

  return (
    <SPaymentsTab>
      {
        (showFansPaymentsFormatted && showFansPaymentsFormatted.length) ? showFansPaymentsFormatted.map(payment => {
          return (
            <Row css={`margin-bottom: 5px;`}>
              <Col css={`padding-right: 15px; color: var(--color-content-grey);`}>{payment.dateFormatted}</Col>
              <Col css={`padding: 0 15px; color: var(--color-content-grey);`} flex={`1 0 0`}>{payment.source}</Col>
              <Col css={`padding: 0 15px;`}>
                <Amount refund={payment.refund}>
                  ${Math.abs(payment.amount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  {payment.refund && " refund"}
                </Amount>
              </Col>
            </Row>
          );
        }) : (<SNone>no payments made</SNone>)
      }
    </SPaymentsTab>
  )
};

export default PaymentsTab;
