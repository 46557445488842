import React, { useState, useMemo, useEffect } from "react";
import styled from "styled-components";
import { useMutation, useQuery } from "@apollo/client";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import {
  GET_SUPPORTING_PAYMENT_METHOD,
  GIVE_SUPPORT_MUTATION,
} from "apps/PublicPage/graphql";

import { ElementsProvider } from "providers/StripeProvider";
import {
  CardDescription,
  CardInner,
  CardTitle,
  Col,
  Form,
  Divider,
  FormItemUIOnly,
  FormItem,
  Row,
  Button,
  A,
  Skeleton,
  FormItemError,
  Spacer,
  Input,
  TextArea,
} from "atoms";
import CardDetails from "components/payment/CardDetails";
import { TRUE } from "consts";
import { default as VisaIcon } from "icons/Visa";
import { GENERAL_TIPS_THANK_YOU } from "graphql-api";

const SGiveGeneralTips = styled(CardInner)`
  width: 100%;
  padding-top: 0;
  padding-bottom: 0;
`;
const SavedCardRow = styled.div`
  padding: 10px 0 13px;
`;
const CardNumberInfo = styled.div``;
const VisaIconContainer = styled.div`
  width: 22px;
  padding-right: 5px;
  padding-bottom: 2px;
  box-sizing: content-box;
  color: var(--color-grey-lighter);
  line-height: 0;
`;

const SFormItemError = styled(FormItemError)``;

const SFormItemUIOnly = styled(FormItemUIOnly)`
  margin-bottom: 0;
`

const SDivider = styled(Divider)`
  margin: 20px -25px;
  width: auto;
`

const SCardTitle = styled(CardTitle)`
  font-size: 15px;
  padding-bottom: 8px;
`

const SMessageLabel = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
`

const SDesc = styled.div`
  color: #777;
`

const GiveGeneralTipsForm = (
  {
    amount,
    showId,
    onSuccess,
    onCancel,
    onError,
  }) => {
  const [isConfirmPaymentBusy, setIsConfirmPaymentBusy] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const [cardDetailsComplete, setCardDetailsComplete] = useState(false);
  const [cardDetailsError, setCardDetailsError] = useState(null);
  const [stripeCardIncompleteError, setStripeCardIncompleteError] =
    useState(false);
  const [isInChangeCard, setIsInChangeCard] = useState(false);
  const pAmount = useMemo(() => amount, [amount]);

  const { data: supportingPaymentMethodData, loading } = useQuery(
    GET_SUPPORTING_PAYMENT_METHOD,
    {
      fetchPolicy: "network-only",
    }
  );

  const supportingPaymentMethod = useMemo(
    () => supportingPaymentMethodData?.getSupportingPaymentMethod,
    [supportingPaymentMethodData]
  );

  const { brand, last4 } = useMemo(
    () => supportingPaymentMethod || {},
    [supportingPaymentMethod]
  );

  const hasSavedCard = useMemo(() => !!last4, [last4]);
  const isInSavedCard = useMemo(
    () => hasSavedCard && !isInChangeCard,
    [hasSavedCard, isInChangeCard]
  );

  const [stripeApiError, setStripeApiError] = useState(null);
  const [form] = Form.useForm();
  const [isTokenBusy, setIsTokenBusy] = useState(false);

  const handleReset = () => {
    form.resetFields()
  }

  const [triggerGiveGeneralTips, { loading: isGiveGeneralTipsBusy }] = useMutation(
    GIVE_SUPPORT_MUTATION,
    {
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: GENERAL_TIPS_THANK_YOU,
          variables: { showId },
        },
      ],
      onCompleted({ giveSupport: { error, success, message } }) {
        if (success === TRUE) {
          setIsConfirmPaymentBusy(true);
          handleAfterSuccess({ message });
        } else {
          setStripeApiError({ message: error });
        }
      },
    }
  );

  const handleCancel = () => {
    onCancel && onCancel()

    handleReset()
  }

  const handleAfterSuccess = ({ message }) => {
    onSuccess && onSuccess({ message });

    setIsConfirmPaymentBusy(false);

    handleCancel()
  };

  const handleFinish = async ({ paymentMessage }) => {
    try {
      if (isInSavedCard) {
        await triggerGiveGeneralTips({
          variables: {
            showId,
            cents: (isNaN(pAmount) ? Number(pAmount.replace(',', '')) : pAmount) * 100,
            paymentMessage
          },
        });
        return;
      }

      if (!cardDetailsComplete) {
        !cardDetailsError && setStripeCardIncompleteError(true);
        return;
      }

      const cardElement = elements.getElement(CardElement);

      setIsTokenBusy(true);
      const {
        token: { id: tokenId },
      } = await stripe.createToken(cardElement);

      await triggerGiveGeneralTips({
        variables: {
          showId,
          cents: (isNaN(pAmount) ? Number(pAmount.replace(',', '')) : pAmount) * 100,
          token: tokenId,
          paymentMessage,
        },
      });

      setIsTokenBusy(false);
    } catch (e) {
      onError && onError();
    }
  };

  const handleCardDetailsChange = (event) => {
    setStripeApiError(null);
    setStripeCardIncompleteError(false);
    setCardDetailsComplete(event.complete);
    setCardDetailsError(event.error);
  };

  return (
    <Form onFinish={handleFinish} initialValues={{
      paymentMessage: ''
    }} form={form}>
      {loading && (<Skeleton/>)}
      {!loading && isInSavedCard && (
        <SFormItemUIOnly>
          <SCardTitle>Payment Info</SCardTitle>
          <SavedCardRow>
            <Row align="middle">
              <Col>
                <VisaIconContainer>
                  <VisaIcon/>
                </VisaIconContainer>
              </Col>
              <Col>
                <CardNumberInfo>
                  {brand} xxxx {last4}
                </CardNumberInfo>
              </Col>
              <Spacer/>
              <Col>
                <A onClick={() => setIsInChangeCard(true)}>change card</A>
              </Col>
            </Row>
          </SavedCardRow>
          {stripeApiError && (
            <FormItemError>{stripeApiError.message}</FormItemError>
          )}
        </SFormItemUIOnly>
      )}
      {!loading && !isInSavedCard && (
        <SFormItemUIOnly>
          <SCardTitle>Payment Info</SCardTitle>
          <CardDetails onChange={handleCardDetailsChange}/>
          {stripeApiError && (
            <FormItemError>{stripeApiError.message}</FormItemError>
          )}
          {stripeCardIncompleteError && (
            <FormItemError>All fields are required</FormItemError>
          )}
        </SFormItemUIOnly>
      )}
      <SMessageLabel>
        <SCardTitle>Message</SCardTitle>
        <SDesc>optional</SDesc>
      </SMessageLabel>
      <FormItem
        name="paymentMessage"
        className={'mb-0'}
        rules={[
          { type: 'string', max: 2000, message: "You've exceeded the 2,000 character limit" },
        ]}
      >
        <TextArea
          rows={3}
          placeholder={'Include a public message'}
        />
      </FormItem>
      <SDivider/>
      <Button
        disabled={!pAmount || amount * 1 == 0}
        htmlType="submit"
        block
        loading={loading || isTokenBusy || isGiveGeneralTipsBusy || isConfirmPaymentBusy}
      >
        give support
      </Button>
      <Button
        onClick={handleCancel}
        block
        type="link"
      >
        cancel
      </Button>
    </Form>
  );
};

const AmountInputContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 500;
  color: var(--color-green);
  border: 1px solid var(--color-border);
  width: 100px;
  border-radius: 2px;
  padding-left: 8px;
`;

const AmountPrefix = styled.div`
  padding-left: 4px;
`

const AmountInput = styled(Input)`
  color: var(--color-green);
  border: 0;
  outline: 0;
  box-shadow: none;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &:active, &:focus {
    outline: 0;
    box-shadow: none;
  }

  /* Firefox */

  &[type="number"] {
    -moz-appearance: textfield;
  }
`;

const SCardDescription = styled(CardDescription)`
  color: #777;
  text-align: center;
  font-size: 90%;
`
const TermsLink = 'https://www.fanlist.com/terms/';
const PrivacyLink = 'https://www.fanlist.com/privacy/';

const GiveGeneralTips = (
  {
    amount,
    showId,
    onSuccess,
    onCancel,
    onError
  }) => {
  const [theAmount, setAmount] = useState(amount);
  const [validateError, setValidateError] = useState(false);
  const greaterZero = "Amount must be more than 0";
  const greaterTwo = "please limit each donation to $2,000";
  const [validateAmount, setValidateAmount] = useState(greaterZero);

  const handleChangeAmount = (v) => {
    let vNumber = v ? Number(v) : -1;
    if (vNumber <= 2000) {
      setValidateError(false);

      if (vNumber === 0) {
        setValidateAmount(greaterZero);
        setValidateError(true);
      }

      const formattedV =
        vNumber > -1
          ? vNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          : "";

      setAmount(formattedV);
    } else {
      setValidateAmount(greaterTwo);
      setValidateError(true);
    }
  }

  const handleChange = (e) => {
    const v = e.target.value.replace(/[^\d]/g, "").slice(0, 4);
    handleChangeAmount(v)
  };

  const handleSuccess = ({ message }) => {
    onSuccess && onSuccess({ message })

    handleChangeAmount(0)
  }

  const handleCancel = () => {
    handleChangeAmount(0)

    onCancel && onCancel()
  }

  useEffect(() => handleChangeAmount(amount), [amount]);

  return (
    <SGiveGeneralTips>
      <CardTitle css={`font-size: 15px; padding-bottom: 8px;`}>Amount</CardTitle>
      <AmountInputContainer>
        <AmountPrefix>$</AmountPrefix>
        <AmountInput
          type="string"
          value={theAmount}
          autoFocus
          onChange={handleChange}
          style={{ width: `100px`, fontSize: `18px`, padding: `12px 12px 12px 4px` }}
        />
      </AmountInputContainer>
      {validateError && (
        <SFormItemError>
          {validateAmount}
        </SFormItemError>
      )}
      <CardInner css={`
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 0;
      `}>
        <ElementsProvider>
          <GiveGeneralTipsForm
            amount={theAmount}
            showId={showId}
            onSuccess={handleSuccess}
            onCancel={handleCancel}
            onError={onError}
          />
        </ElementsProvider>
        <SCardDescription>
          By giving, you agree to our <A href={TermsLink} target="_blank">
          terms
        </A> & <A
          href={PrivacyLink}
          target="_blank"
        >
          privacy policy
        </A>
        </SCardDescription>
      </CardInner>
    </SGiveGeneralTips>
  );
};

export default GiveGeneralTips;
