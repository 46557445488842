import React, { createContext, useContext, useState, useMemo } from "react";
import { useQuery } from "@apollo/client";
import { ME_QUERY } from "graphql-api";
import { SHOW_QUERY, STRIPE_ACCOUNT } from 'apps/Dashboard/graphql';
import { Membership, Show } from "types";

import {
  OWNER,
  FAN,
  MEMBER,
  TRUE,
  IS_CONFIRMED_STATUS,
} from "consts";

import {
  message,
} from "atoms";

export const DashboardContext = createContext<{
  mainMenuOpened: boolean
  avatarMenuOpened: boolean
  show: Show
}>({
  mainMenuOpened: false,
  avatarMenuOpened: false,
  show: null
});

export const DashboardStateProvider = (props) => {
  const [mainMenuOpened, setMainMenuOpened] = useState(false);
  const [avatarMenuOpened, setAvatarMenuOpened] = useState(false);
  const {
    data: stripeData,
    loading: stripeLoading,
    error: stripeError,
    refetch: refetchStripeAccountQuery
  } = useQuery(STRIPE_ACCOUNT);
  const stripeAccount = useMemo(() => stripeData?.stripeAccount, [stripeData]);
  const {data: meData, loading} = useQuery(ME_QUERY, {
    onCompleted({me: {id, isConfirmed}}) {
      if (!localStorage.getItem(`${IS_CONFIRMED_STATUS}_${id}`)) {
        localStorage.setItem(`${IS_CONFIRMED_STATUS}_${id}`, isConfirmed ? TRUE : 'false');
      }

      const isConfirmedStatus = localStorage.getItem(`${IS_CONFIRMED_STATUS}_${id}`);
      if (isConfirmed && isConfirmedStatus && isConfirmedStatus !== TRUE) {
        localStorage.setItem(`${IS_CONFIRMED_STATUS}_${id}`, TRUE);
        message.success({content: "Your Account Is Now Active"});
      }
    },
  });

  const me = useMemo(
    () => meData?.me || {type: window.gon.currentUser.type || OWNER},
    [meData]
  );
  const {data: showData, loading: showLoading} = useQuery(SHOW_QUERY);
  const show = useMemo(() => showData?.show, [showData]);
  const isStripeConnected = useMemo(() => !!stripeAccount?.connected || false, [stripeAccount]);
  const [isFanPageSetup, setIsFanPageSetup] = useState(false);

  const computedType = useMemo(() => {
    if (me?.type === FAN) {
      if (me?.viewingShow) {
        return MEMBER;
      }
    }
    return me.type;
  }, [me]);

  const isConfirmed = useMemo(() => {
    return me?.isConfirmed === true
  }, [me]);
  const onboardingCompleted = useMemo(
    () => !!show?.onboardingCompletedAt,
    [show]
  );

  // TODO: strange logic, why return null?
  if ((loading || stripeLoading) && !meData) return null;

  const {showShowsTab} = me;

  const toggleMainMenu = () => {
    if (avatarMenuOpened) {
      setAvatarMenuOpened(false);
    }
    setMainMenuOpened(!mainMenuOpened);
  };
  const toggleAvatarMenu = () => {
    if (mainMenuOpened) {
      setMainMenuOpened(false);
    }
    setAvatarMenuOpened(!avatarMenuOpened);
  };

  return (
    <DashboardContext.Provider
      value={{
        me,
        showLoading,
        show,
        userType: computedType,
        isConfirmed,
        showShowsTab,
        mainMenuOpened,
        avatarMenuOpened,
        setAvatarMenuOpened,
        stripeState: {
          stripeLoading,
          stripeAccount,
          isStripeConnected,
          stripeError,
          refetchStripeAccountQuery,
        },
        toggleMainMenu,
        toggleAvatarMenu,
        isFanPageSetup,
        setIsFanPageSetup,
        onboardingCompleted
      }}
    >
      {props.children}
    </DashboardContext.Provider>
  );
};

export const withDashboardState = (Component) => {
  return (props) => {
    const dashboardState = useContext(DashboardContext);
    return <Component {...props} dashboardState={dashboardState}/>;
  };
};
