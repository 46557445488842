import React, { useMemo, useState } from "react";
import { Switch } from "react-router-dom";
import styled, { withTheme } from "styled-components";
import {
  Menu,
  MenuItem,
  DrawerInner,
  PageHeader,
  PageContent,
  A,
  message,
  SkeletonCard,
  Row,
  Col,
  Avatar,
  ErrorCard,
} from "atoms";
import { useMutation, useQuery } from "@apollo/client";
import { TRUE } from "consts";
import { FaTrashAlt } from "icons";
import SocialList from "components/SocialList";
import MembershipTab from "./MembershipTab";
import PaymentsTab from "./PaymentsTab";
import { DeleteCta } from "molecules";
import { MANUAL_CANCEL_MEMBERSHIP_MUTATION } from "apps/Dashboard/graphql";
import DataProvider from "providers/DataProvider";
import { FAN_QUERY } from "graphql-api";

const ImageContainer = styled.div`
  margin-bottom: 5px;
  ${(props) => props.css}
`;

const UserName = styled.div`
  margin-bottom: 10px;
  font-size: 16px;
  ${(props) => props.css}
`;

const SMenuItem = styled(MenuItem)`
  padding-block-end: 4px;
`;

const SA = styled(A)`
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  gap: 10px;
  padding-left: 3px;
  font-size: 15px;
`;

const MemberDetails = ({ fan, theme: { isMobile }, onCancel }) => {
  const {
    displayName,
    fullName,
    avatarUrl,
    twitter,
    instagram,
    website,
    user,
    membership,
  } = fan;
  const [activeTab, setActiveTab] = useState("membership");

  const [triggerCancelMembership, { loading: cancelBusy }] = useMutation(
    MANUAL_CANCEL_MEMBERSHIP_MUTATION,
    {
      variables: {
        membershipId: membership ? membership.id : null,
      },
      onCompleted({ cancelFansMembership: { success, error, membership } }) {
        if (success === TRUE) {
          message.success({ content: "Subscription Canceled" });
          onCancel && onCancel();
        } else {
          message.error({
            content: "Something went wrong, please try again later!",
          });
        }
      },
    }
  );

  const handleCancel = async () => {
    await triggerCancelMembership();
  };

  return (
    <>
      <DrawerInner>
        <Row>
          <Col flex={`1 0 0`}>
            <UserName
              css={`
                margin-bottom: 2px;
              `}
            >
              {fullName}
            </UserName>
            <UserName
              css={`
                color: var(--color-content-grey);
                font-size: 14px;
                margin-bottom: 8px;
              `}
            >
              {displayName}
            </UserName>
            <SocialList
              instagram={instagram}
              twitter={twitter}
              website={website}
              email={user.email}
            />
          </Col>
          <Col>
            <ImageContainer css={isMobile ? "width: 80px;" : `width: 100px;`}>
              <Avatar
                src={avatarUrl}
                placeholder={(displayName || fullName)
                  .slice(0, 1)
                  .toUpperCase()}
                circle={false}
                width={isMobile ? "80px" : `100px`}
                radius={"5px"}
              />
            </ImageContainer>
          </Col>
        </Row>
      </DrawerInner>
      <DrawerInner
        css={`
          flex: 1 0 0;
        `}
      >
        <PageHeader>
          <Menu tab mode="horizontal" selectedKeys={[activeTab]}>
            <SMenuItem key={"membership"}>
              <A onClick={() => setActiveTab("membership")}>Subscription</A>
            </SMenuItem>
            <SMenuItem key={"payments"}>
              <A onClick={() => setActiveTab("payments")}>Payments</A>
            </SMenuItem>
          </Menu>
        </PageHeader>
        <PageContent>
          <Switch>
            {activeTab === "membership" && (
              <MembershipTab membership={membership} />
            )}
            {activeTab === "payments" && <PaymentsTab userId={user.id} />}
          </Switch>
        </PageContent>
      </DrawerInner>
      {activeTab === "membership" &&
        membership &&
        membership.status === "active" && (
          <DrawerInner>
            <DeleteCta
              title={"Are you sure?"}
              text={
                "Cancelling this fan's subscription will end their subscription and billing."
              }
              onDelete={handleCancel}
              loading={cancelBusy}
              showNo={true}
            >
              <A block>
                <FaTrashAlt />
                &nbsp;cancel subscription
              </A>
            </DeleteCta>
          </DrawerInner>
        )}
    </>
  );
};

const MemberDetailsWithTheme = withTheme(MemberDetails);

const MemberDetailsWithWithResolver = (props) => {
  const { id, membershipId, userId, showId, ...memberProps } = props;
  const { data, loading, error } = useQuery(FAN_QUERY, {
    variables: {
      id,
      membershipId,
      userId,
      showId,
    },
  });
  if (loading) {
    return <SkeletonCard />;
  }
  if (error || !data) {
    return <ErrorCard />;
  }
  return <MemberDetailsWithTheme fan={data.fan} {...memberProps} />;
};

const MemberDetailsContainer = (props) => {
  const { fan, ...memberProps } = props;

  if (fan) {
    return <MemberDetailsWithTheme fan={fan} {...memberProps} />;
  }
  return <MemberDetailsWithWithResolver {...props} />;
};

export default MemberDetailsContainer;
