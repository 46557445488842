import React from "react";

import { useQuery } from "@apollo/client";
import { Mentions, Skeleton } from "antd";
import { PopoverInner } from "atoms";
import { PUBLIC_SHOW_CHAT_CONTACT_QUERY } from "graphql-api";
import UserProfile from "components/UserProfile";
import styled from "styled-components";

export const LazyUserProfile = ({contactId}) => {
  const {
    loading,
    data,
    error
  } = useQuery(PUBLIC_SHOW_CHAT_CONTACT_QUERY, {
    variables: {
      contactId
    },
  });
  if (loading) {
    return (
      <PopoverInner>
        <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
          <Skeleton.Avatar active size='large' />
          <Skeleton.Node active style={{height: 12, width: 100}}><div /></Skeleton.Node>
          <Skeleton.Node active style={{height: 12, width: 150}}><div /></Skeleton.Node>
          <Skeleton.Node active style={{height: 12, width: 150}}><div /></Skeleton.Node>
        </div>
      </PopoverInner>
    )
  }
  if (error) {
    return (
      <PopoverInner>
        <div>sorry, something went wrong</div>
      </PopoverInner>
    )
  }
  return <UserProfile user={data.publicShowChatContact} />
}
export const MessageText = styled.div`
  font-size: 14px;
  white-space: pre-line;
  overflow-wrap: break-word;
  .mention_user {
    color: var(--color-primary);
    cursor: pointer;
    &:hover {
      color: var(--color-primary-hover);
    }
  }
  .hicon {
    font-size: 1.2em;
    vertical-align: middle;
  }
`;

export const SMentions = styled(Mentions)`
  padding: 6px 0 6px 2px;
  background-color: #f9f9f9;
  border-radius: 4px;
  border-color: var(--color-border);

  textarea {
    background-color: #f9f9f9;
    font-size: 14px;
  }
  &:hover, &:focus-within {
    textarea {
      background-color: white;
    }
  }
  ${props => props.disabled && `
    textarea::placeholder {
      color: #e6aab7;
    }
  `}
`;