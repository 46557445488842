import React, { useContext } from "react";
import styled from "styled-components";
import {
  Button,
  Card,
  CardDescription,
  CardInner,
  CardTitle,
  Div,
  Flexbox,
  Modal,
} from "atoms";
import { Show } from "types";
import { useState } from "react";
import BecomeMember from "apps/PublicPage/components/BecomeMember";
import { FanPageContext } from "apps/PublicPage/context";
import { SectionHeader, SectionTitle } from "apps/PublicPage/components/FanPage";
import { Col, Flex, Row } from "antd";
import { FaComments, FaLock } from "icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComments } from "@fortawesome/free-solid-svg-icons";

const ModalInner = styled.div`
  padding: 0 0 20px;
`;

const ChatPaywall = () => {
  const { show, refetch: refetchShowQuery } = useContext(FanPageContext);
  const [joinVisible, setJoinVisible] = useState(false);

  const handleJoin = () => {
    setJoinVisible(true);
  };
  return (
    <div className="ChatPaywall">
      <SectionHeader>
        <SectionTitle>Chat Room</SectionTitle>
      </SectionHeader>
      <Card simple shadow className="mb-4">
        <CardInner>
          <Row gutter={12} align='middle'>
            <Col>
              <Flex align="center" justify="center" style={{
                width: '50px',
                height: '50px',
                backgroundColor: 'rgba(197, 197, 197, 0.7)',
                borderRadius: '3px'
              }}>
                <FontAwesomeIcon icon={faComments} fontSize={20} color="white" />
              </Flex>
            </Col>
            <Col>
              <FaLock color="var(--text-disabled)" verticalMiddle css="font-size: 20px;" />
            </Col>
            <Col>
              <CardDescription>Subscribe to join this chat</CardDescription>
            </Col>
          </Row>
        </CardInner>
      </Card>
      <Button block onClick={handleJoin}>
        SUBSCRIBE TO GET ACCESS
      </Button>
      <Modal
        open={joinVisible}
        onCancel={() => setJoinVisible(false)}
        simple
        width={380}
        title="Subscribe to get access"
      >
        <ModalInner>
          <BecomeMember show={show} refetchShowQuery={refetchShowQuery} />
        </ModalInner>
      </Modal>
    </div>
  );
};

export default ChatPaywall;
