import React, { useState, useEffect, useMemo, useContext } from "react";
import styled from "styled-components";
import { PublicPageContext } from "apps/PublicPage/context";
import { useMutation } from "@apollo/client";
import {
  CANCEL_MEMBERSHIP_MUTATION,
} from "graphql-api";
import { formatFromIos } from "utils/Datetime";
import { Menu } from "antd";
import {
  Col,
  Row,
  Card,
  CardInner,
  CardHeader,
  CardTitle,
  CardDescription,
  Button,
  Amount,
  A,
  Dropdown,
  message,
  Alert,
  afterModalClose,
  Modal,
} from "atoms";
import { supportUnits } from "micro-data";

import { DeleteCta } from "molecules";
import { TRUE } from "consts";
import { FaTrashAlt, FaEllipsisV, FaCreditCard } from "icons";
import { UpdateMembershipCard } from "components/payment/SetupCreditCard";
import BecomeMember from "./BecomeMember";

const AmountList = styled.div``;

const ControlMenu = styled(Menu)`
  min-width: 200px;
`;

const MenuItem = styled(Menu.Item)`
  padding: 10px 20px;
  display: flex;
  align-items: center;
  font-size: 15px;

  .ant-dropdown-menu-item-icon {
    margin-right: 20px;
    min-width: 15px;
    color: var(--color-content-grey);
  }

  span {
    display: flex;
    flex: 1 0 0;
    justify-content: space-between;
  }

  :hover {
    color: var(--color-primary);

    .ant-dropdown-menu-item-icon {
      color: inherit;
    }
  }
`;

const Inner = styled.div<{ css?: string }>`
  padding: 10px 15px;

  ${(props) => props.css}
  .ant-typography-expand {
    color: var(--color-primary);

    &:hover {
      color: var(--color-primary-hover);
    }
  }
`;

const Control = styled.a<{ active?: boolean; disabled?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 16px;
  cursor: pointer;
  color: var(--color-content-grey);
  min-width: 18px;

  ${(props) =>
    !props.disabled &&
    `:hover {
    color: var(--color-primary);
  }`}

  ${(props) =>
    !props.disabled && props.active && `color: var(--color-primary);`}
`;

const ControlInner = styled(Inner) <{ last?: boolean }>`
  height: 100%;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-size: 14px;
  ${(props) => props.last && `border-right: none;`}
`;

const SPendingCancelAlert = styled.div`
  margin-top: 10px;
`;

const CancelMenuItem = ({ onCancel, loading, ...props }) => {
  return (
    <DeleteCta
      title={"Are you sure?"}
      text={
        "Cancelling my subscription will end my subscription at the end of my billing cycle."
      }
      onDelete={onCancel}
      loading={loading}
      showNo={true}
    >
      <MenuItem icon={<FaTrashAlt />} {...props}>
        Cancel Subscription
      </MenuItem>
    </DeleteCta>
  );
};

const TierSelection = ({
  show,
  membership,
  me,
  refetchShowQuery,
}) => {
  const { isLoggedIn } = useContext(PublicPageContext);
  const [cancelControlsVisible, setCancelControlsVisible] = useState(false);
  const [updateCardModalVisible, setUpdateCardModalVisible] = useState(false);
  const subscribedMember = useMemo(
    () => !!membership && membership.accessType !== "canceled",
    [membership]
  );

  const handleError = () => {
    // @ts-ignore
    message.error({ content: "Something went wrong, please try again later." });
  };

  const [triggerCancelMembership, { loading: isCancelBusy }] = useMutation(
    CANCEL_MEMBERSHIP_MUTATION,
    {
      onCompleted({ cancelMembership: { error, success, show } }) {
        if (success === TRUE) {
          message.success("Subscription Canceled");
          refetchShowQuery && refetchShowQuery();
        } else {
          handleError();
        }
      },
      onError(errorPayload) {
        handleError();
      },
    }
  );

  const handleCancelControlsOpenChange = (e) => {
    setCancelControlsVisible(e);
  };

  const handleCancelMembership = async (membershipId: any) => {
    await triggerCancelMembership({
      variables: {
        membershipId,
      },
    });
  };

  const handlePaymentUpdate = () => {
    setUpdateCardModalVisible(false);
    afterModalClose(() => {
      message.success({ content: "Credit card updated" });
    });
  };

  const handleCloseUpdate = () => {
    setUpdateCardModalVisible(false);
  };

  const handleOpenUpdateModal = () => {
    setUpdateCardModalVisible(true);
  };

  useEffect(() => {
    return () => {
      refetchShowQuery && refetchShowQuery();
    };
  }, [isLoggedIn]);

  return (
    <>
      <AmountList>
        {!!subscribedMember && (
          <Card
            simple
            shadow
            css={`
              margin-bottom: 20px;
            `}
          >
            <CardHeader>
              <CardTitle>
                <Row>
                  <Col flex={`1 0 0`}>Subscription</Col>
                  <Col
                    css={`
                      display: flex;
                      align-items: center;
                      justify-content: space-between;
                    `}
                    >
                    {supportUnits[membership.accessType] ? (
                      <Col
                        css={`
                          color: var(--color-content-grey);
                          display: flex;
                          align-items: center;
                        `}
                      >
                        {/*@ts-ignore*/}
                        <Amount size={`14`} color="green" inline>
                          ${Math.round(membership.amountCents / 100)}
                        </Amount>
                        /{supportUnits[membership.accessType]}
                      </Col>
                    ) : (
                      <Col
                        css={`
                          display: flex;
                          align-items: center;
                          color: ${membership.accessType === "gifted" ? 'var(--color-content-grey)' : 'var(--color-primary)'};
                        `}
                      >
                        {membership.accessType.replace("_", " ")}
                      </Col>
                    )}
                    {isLoggedIn && !!subscribedMember && me.id === membership.user.id && 
                      membership.accessType !== "cancel_pending" && 
                      membership.accessType !== "past_due" && (
                        <Dropdown
                          trigger={["click"]}
                          open={cancelControlsVisible}
                          arrow
                          onOpenChange={handleCancelControlsOpenChange}
                          key={"CANCEL"}
                          overlay={
                            <ControlMenu>
                              <MenuItem
                                icon={<FaCreditCard />}
                                onClick={handleOpenUpdateModal}
                              >
                                Update Credit Card
                              </MenuItem>
                              <CancelMenuItem
                                key={"DELETE"}
                                onCancel={() => handleCancelMembership(membership.id)}
                                loading={isCancelBusy}
                              />
                            </ControlMenu>
                          }
                        >
                          <Control>
                            <ControlInner>
                              <FaEllipsisV />
                            </ControlInner>
                          </Control>
                        </Dropdown>
                      )}
                  </Col>
                </Row>
              </CardTitle>
            </CardHeader>
            {membership.accessType === "cancel_pending" && (
              <SPendingCancelAlert>
                <Alert
                  type={"error"}
                  description={`Your subscription was canceled on ${formatFromIos(
                    membership.cancelRequestedAt
                  )}. You will no longer be billed and your subscription ends on ${formatFromIos(
                    membership.nextBillAt
                  )}.`}
                />
              </SPendingCancelAlert>
            )}
            {membership.accessType === "past_due" && (
              <>
                <SPendingCancelAlert>
                  <Alert
                    type="error"
                    description={
                      <>
                        We had an issue charging your credit card. Please{" "}
                        <A onClick={handleOpenUpdateModal}>
                          update your credit card
                        </A>{" "}
                        to keep your subscription active.
                      </>
                    }
                  ></Alert>
                </SPendingCancelAlert>
                <div className="mt-2">
                  <Button block onClick={handleOpenUpdateModal}>
                    update credit card
                  </Button>
                </div>
              </>
            )}
          </Card>
        )}

        {(!subscribedMember || membership.accessType === "past_due") && (
          <Card simple shadow>
            <BecomeMember show={show} refetchShowQuery={refetchShowQuery} />
          </Card>
        )}
      </AmountList>
      {["monthly", "yearly"].includes(membership?.tier.kind) &&
        !["cancel_pending", "canceled"].includes(membership.status) && (
          <Modal
            open={updateCardModalVisible}
            width={380}
            zIndex={1040}
            destroyOnClose
            title={"UPDATE CREDIT CARD"}
            onCancel={(e) => setUpdateCardModalVisible(false)}
          >
            <UpdateMembershipCard
              onSetup={handlePaymentUpdate}
              onClose={handleCloseUpdate}
              membershipId={membership.id}
            />
          </Modal>
        )}
    </>
  );
};

export default TierSelection;
