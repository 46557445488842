import { useQuery } from "@apollo/client";
import { Alert, Card, CardInner, SkeletonCard, Span } from "atoms";
import { CHAT_MESSAGE_QUERY, CHAT_MESSAGES_QUERY } from "graphql-api";
import React, { useContext } from "react";
import { ChatMessage, ID } from "types";
import ChatReplyItem from "./ChatReplyItem";
import ChatReplyList from "./ChatReplyList";
import { Flex } from "antd";
import { FanPageContext } from "apps/PublicPage/context";
import { forwardRef } from "react";

const ChatReplyContent = (props: { parentId: ID }, ref) => {
  const { parentId } = props;
  const { show } = useContext(FanPageContext);
  const { loading, data } = useQuery<{chatMessage: ChatMessage}>(CHAT_MESSAGE_QUERY, {
    variables: {
      id: parentId
    }
  });

  // prefetch replies early
  const { loading: listLoading } = useQuery(CHAT_MESSAGES_QUERY, {
    // fetchPolicy: 'no-cache',
    variables: {
      showId: show.id,
      parentId: props.parentId,
      first: 100
    }
  });
  const chatMessage = data?.chatMessage;

  if (loading || listLoading) {
    return (
      <SkeletonCard />
    )
  }
  if (!chatMessage) {
    return <Alert message='Something went wrong' />
  }
  return (
    <div className="ChatReplyContent">
      <Card simple shadow className="mb-3">
        <CardInner thin>
          <ChatReplyItem message={chatMessage} />
        </CardInner>
      </Card>
      <Flex align='center' gap={16} className="mb-3" ref={ref}>
        <Span>{chatMessage.replyCount} replies</Span>
        <div style={{flex: 1, height: '1px', backgroundColor: 'var(--light-grey)'}}></div>
      </Flex>
      <Card simple shadow>
        <CardInner thin>
          <ChatReplyList parentId={parentId} />
        </CardInner>
      </Card>
    </div>
  )
}

export default forwardRef(ChatReplyContent);
