import React, { useContext } from "react";
import colors from "index.jsx"
import { EpisodeType } from "types";
import { Card, CardInner, CardTitle, Col, Div, HtmlEllipsis, Image, Link, Row } from "atoms";
import { PreviewPlayer } from "libraries/Audio/components/AudioRecord/components";
import PreviewPlayerInactive from "libraries/Audio/components/AudioRecord/components/PreviewPlayerInactive";
import { formatSameAsISOTime } from "utils/Datetime";
import { FanPageContext } from "../context";
import styled, { ThemeContext } from "styled-components";
import { CardDescription, CardGreyInner } from "atoms/Card";

const PremiumCard = (props: { episode: EpisodeType, inactive?: boolean }) => {
  const { show } = useContext(FanPageContext);
  const { episode, inactive = false } = props;
  const { isMobile } = useContext(ThemeContext);

  const EImageCard = styled(Image)`  
    @media screen and (min-width: 1024px) {
      width: 110px;
    }

    @media screen and (min-width: 900px) and (max-width: 1023px) {
      width: 100px;
    }

    @media screen and (min-width: 768px) and (max-width: 899px) {
      width: 90px;
    }

    @media screen and (max-width: 767px) {
      width: 110px;
    }
  `;


  const handlePlayerClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
  }
  return (
    <Card simple shadow>
      <CardInner thin>
        <Row gutter={[10, 20]} justify={'space-between'}>
          <Col flex="1 1 0" minZero>
            <Link to={`/${show.slug}/episode/${episode.uuid}`} css="color: inherit !important;">
              <CardTitle css={`font-size: ${ isMobile ? '16px' : '18px'}; padding-bottom: 4px; cursor: pointer;`}>{episode.title}</CardTitle>
            </Link>
            <Div color="newlightGrey" css={'font-size: 13px;'}>{formatSameAsISOTime(episode.publishAt)}</Div>
            {inactive ? (
              <PreviewPlayerInactive simple duration={episode?.audioDuration} />
            ) : (
              <div onClick={handlePlayerClick}>
                <PreviewPlayer src={episode.audioUrl} duration={episode?.audioDuration} simple />
              </div>
            )}
          </Col>
          {!isMobile && (
            <Col style={{ display: 'flex', alignItems: 'unset' }} className="mt-1 mb-1">
              <Link block to={`/${show.slug}/episode/${episode.uuid}`} css="color: inherit !important;">
                <EImageCard src={episode.customArtworkUrl || show.podcast.coverArtUrl} radius="5px" />
              </Link>
            </Col>
          )}
        </Row>
      </CardInner>
    </Card>
  );
};

export default PremiumCard;
