import {
  Card,
  CardDescription,
  CardImage,
  CardInner,
  CardTitle,
  Paragraph,
  Row,
  Col
} from "atoms";
import React, { useState, useMemo } from "react";
import { SponsorType } from "types";
import { FaExtraLinkAlt } from 'icons';
import { CardPlayer } from "libraries/Audio/components/AudioRecord/components";
import styled, { withTheme } from 'styled-components';

const SA = styled.a`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SCardTitle = styled(CardTitle)`
  color: #000000;
  text-transform: none;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;

  :hover {
    color: var(--color-primary-hover);
  }
`;

const SCardPlayer = styled.div`
  margin-top: -40px;
  margin-bottom: -15px;
`;

type PropsType = {
  sponsor: SponsorType;
  theme;
};

const SponsorCard = (props: PropsType): JSX.Element => {
  const {
    sponsor,
    theme: {isMobile}
  } = props;

  const [descriptionExpanded, setDescriptionExpanded] = useState(false);
  const fullUrlLink = useMemo(() =>
      sponsor?.fullUrlLink ? (
          (sponsor?.fullUrlLink?.startsWith('http') && sponsor?.fullUrlLink?.includes('://'))
            ? sponsor?.fullUrlLink
            : sponsor?.fullUrlLink?.startsWith('://') ?
              `https${sponsor?.fullUrlLink}`
              : `https://${sponsor?.fullUrlLink}`
        )
        : null,
    [sponsor]
  );

  return (
    <Card simple shadow css="height: 100%">
      {
        fullUrlLink ? (
          <a href={fullUrlLink} target="_blank">
            <CardImage image={sponsor?.sponsorImageUrl} aspectRatio={2.5}/>
          </a>
        ) : (
          <CardImage image={sponsor?.sponsorImageUrl} aspectRatio={2.5}/>
        )
      }
      {
        sponsor?.sponsorAudioUrl && (
          <SCardPlayer>
            <CardPlayer
              src={sponsor?.sponsorAudioUrl}
              duration={sponsor?.sponsorAudioDuration}
            />
          </SCardPlayer>
        )
      }
      <CardInner thin>
        {
          fullUrlLink ? (
            <SA href={fullUrlLink} target="_blank">
              <Row className={'mb-1'} align="top" gutter={10} style={{ alignItems: 'baseline', width: '110%' }}>
                <Col flex="1 0 0">
                  <SCardTitle>{sponsor?.name}</SCardTitle>
                </Col>
                <Col align="center">
                  <FaExtraLinkAlt css={`vertical-align: middle;`}/>
                </Col>
              </Row>
            </SA>
          ) : (
            <CardTitle
              style={{
                color: "#000000",
                textTransform: "none",
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 2,
                overflow: "hidden",
                textOverflow: "ellipsis",
                textTransform: 'none',
              }}
            >
              {sponsor?.name}
            </CardTitle>
          )
        }
        <CardDescription
          css="margin-bottom: 10px"
          ignoreLineBreak={!descriptionExpanded}
        >
          <Paragraph
            ellipsis={{
              rows: 3,
              expandable: true,
              symbol: "read more",
              onExpand: () => setDescriptionExpanded(true),
            }}
            className="mb-0"
            css={`color: #777;`}
          >
            {sponsor?.description}
          </Paragraph>
        </CardDescription>
      </CardInner>
    </Card>
  );
};

export default withTheme(SponsorCard);
