import React, { useRef, useCallback, useEffect, useLayoutEffect, useContext } from 'react';
import styled from 'styled-components';
import { useQuery } from '@apollo/client';
import { CHAT_MESSAGES_QUERY, SUBSCRIPTION_ADD_CHAT_MESSAGE, SUBSCRIPTION_DELETE_CHAT_MESSAGE } from 'graphql-api';
import { useMemo } from 'react';
import { Col, Row, Avatar, Skeleton, Span } from 'atoms';
import ChatMessageItem from './ChatMessageItem';
import { Show, User } from 'types';
import { parseDate } from 'utils/Datetime';
import WelcomeMessageItem from './WelcomeMessageItem';
import { FanPageContext, PublicPageContext } from 'apps/PublicPage/context';
import { Flex } from 'antd';
import { isElementInViewport } from 'utils';

const ChatMessageItemContainer = styled.div`
  margin-bottom: 15px;
`;
const MsgGroupContainer = styled.div`
  margin-bottom: 10px;
`;
const formatGroupDate = (iosDate) => {
  const date = new Date(iosDate);
  const parsed = parseDate(date);
  return { 'short': `${parsed['Mon']} ${parsed['d']}`, year: parsed['yyyy'] };
}

const ChatMessageList = () => {

  const { me } = useContext(PublicPageContext);
  const { show } = useContext(FanPageContext);
  const { viewerIsAdmin } = show;

  const loadingElmRef = useRef<HTMLDivElement>();

  const { data, loading, subscribeToMore, fetchMore } = useQuery(CHAT_MESSAGES_QUERY, {
    notifyOnNetworkStatusChange: true,
    variables: {
      showId: show.id,
      first: 20,
    }
  });
  const messages = useMemo(() => {
    return data?.chatMessages.edges.map(edge => edge.node) || []
  }, [data]);
  const msgGroupsByDate = useMemo(() => {
    const groups = [];
    let currGroup = {
      date: null,
      dateFormatted: null,
      year: null,
      items: []
    };
    for (const message of messages) {
      const iDate = message.createdAt;
      const { short: iDateFormatted, year } = formatGroupDate(iDate)
      if (iDateFormatted !== currGroup.dateFormatted || year !== currGroup.year) {
        currGroup = {
          date: iDate,
          dateFormatted: iDateFormatted,
          year,
          items: []
        }
        groups.push(currGroup);
      }
      currGroup.items.push(message);
    }
    return groups;
  }, [messages]);
  const pageInfo = useMemo(() => data?.chatMessages.pageInfo, [data]);

  useEffect(() => {
    const unsubscribe = subscribeToMore({
      document: SUBSCRIPTION_ADD_CHAT_MESSAGE,
      variables: {
        showId: show.id
      },
      updateQuery(prev, { subscriptionData }) {
        if (!subscriptionData.data?.chatMessageAdded?.chatMessage) return prev;
        
        const newMessage = subscriptionData.data.chatMessageAdded.chatMessage;
        
        if (newMessage.parentId) return prev
        
        return {
          chatMessages: { ...prev.chatMessages, edges: [{ node: subscriptionData.data.chatMessageAdded.chatMessage }, ...prev.chatMessages.edges] }
        }
      }
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const unsubscribe = subscribeToMore({
      document: SUBSCRIPTION_DELETE_CHAT_MESSAGE,
      variables: {
        showId: show.id
      },
      updateQuery(prev, { subscriptionData }) {
        if (!subscriptionData.data?.chatMessageRemoved?.id) return prev;
        if (subscriptionData.data.chatMessageRemoved.parentMessage) return prev;

        return {
          chatMessages: { ...prev.chatMessages, edges: [...prev.chatMessages.edges].filter(ed => ed.node.id !== subscriptionData.data.chatMessageRemoved.id) }
        }
      }
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if(!loading && pageInfo.hasNextPage && loadingElmRef.current && isElementInViewport(loadingElmRef.current)) {
        fetchMore({
          variables: {
            cursor: pageInfo.endCursor,
            first: 10
          },
        });
      }
    }
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    }
  }, [loading, pageInfo]);

  if (!loading && messages.length === 0) {
    return (
      <div className='ChatMessageList'>
        There are no chat messages
      </div>
    )
  }

  return (
      <div className='ChatMessageList'>
        {
          msgGroupsByDate.map(group => (
            <MsgGroupContainer key={group.date}>
              <Flex align='center' gap={16}>
                <Span color='contentGrey'>{group.dateFormatted}</Span>
                <div style={{flex: 1, height: '1px', backgroundColor: 'var(--light-grey)'}}></div>
              </Flex>
              {
                group.items.map(message => (
                  <ChatMessageItemContainer key={message.id}>
                    {
                      message.kind === 'conversation' ? (
                        <ChatMessageItem message={message} />
                      ) : (
                        <WelcomeMessageItem message={message} me={me} viewerIsAdmin={viewerIsAdmin} />
                      )
                    }
                  </ChatMessageItemContainer>
                ))
              }
            </MsgGroupContainer>
          ))
        }
        {
          (pageInfo?.hasNextPage || loading) && (
            <div ref={loadingElmRef}>
              <div className='mb-2'>
                <Skeleton avatar={{ shape: 'circle', size: 44 }} title={false} loading active paragraph={{ rows: 2 }} />
              </div>
              <div className='mb-2'>
                <Skeleton avatar={{ shape: 'circle', size: 44 }} title={false} loading active paragraph={{ rows: 2 }} />
              </div>
              <div>
                <Skeleton avatar={{ shape: 'circle', size: 44 }} title={false} loading active paragraph={{ rows: 2 }} />
              </div>
            </div>
          )
        }
      </div>
  )
}

export default ChatMessageList;
