import React, { useContext } from "react";
import styled, { ThemeContext } from "styled-components";

import { Menu, MenuItem, TabLink } from "atoms";
import { useMenus } from "../hooks";

const STabLink = styled(TabLink)`
  text-transform: uppercase;
  padding: 0 0 8px 0;
  border-bottom-width: 4px;

  &.active, &:hover {
    color: var(--color-primary);
    border-bottom-width: 4px;
  }
`

const SMenuItem = styled(MenuItem)`
  &.ant-menu-item:hover::after {
    border-bottom-width: 4px !important;
  }
`

const FanPageTabHeader = () => {

  const { isMobile } = useContext(ThemeContext);
  const menus = useMenus();
  return (
    <Menu gutter={isMobile ? 24 : 30} mode="horizontal" tab>
      {
        menus.map((menu) => (
          <SMenuItem key={menu.key}>
            <STabLink exact={menu.to} to={menu.to}>
              {menu.title}
            </STabLink>
          </SMenuItem>
        ))
      }
    </Menu>
  );
};

export default FanPageTabHeader;
