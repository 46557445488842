import React, { useState, useEffect, useMemo, useContext } from "react";
import styled from "styled-components";
import { FanPageContext, PublicPageContext } from "apps/PublicPage/context";
import { useMutation, useQuery } from "@apollo/client";
import {
    ACCESS_MEMBERSHIP_PAYMENT_MUTATION,
} from "graphql-api";
import { GET_SUPPORTING_PAYMENT_METHOD } from "apps/PublicPage/graphql";
import {
    Col,
    Row,
    Card,
    CardInner,
    CardHeader,
    CardTitle,
    CardDescription,
    Button,
    Amount,
    Form,
    Divider,
    FormItemUIOnly,
    A,
    Skeleton,
    FormItemError,
    Spacer,
    message,
    Paragraph,
    OneCollapse,
} from "atoms";
import { supportUnits } from "micro-data";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";

import { ElementsProvider } from "providers/StripeProvider";
import CardDetails from "components/payment/CardDetails";
import { default as VisaIcon } from "icons/Visa";

import { LOGIN, SIGNUP, TRUE } from "consts";
import { FaCheckSquare } from "icons";

const SavedCardRow = styled.div`
  padding: 10px 0 13px;
`;
const CardNumberInfo = styled.div``;
const VisaIconContainer = styled.div`
  width: 22px;
  padding-right: 5px;
  padding-bottom: 2px;
  box-sizing: content-box;
  color: var(--color-grey-lighter);
  line-height: 0;
`;

const SFormItemUIOnly = styled(FormItemUIOnly)`
  margin-bottom: 0;
`;

const SDivider = styled(Divider)`
  margin: 20px -25px;
  width: auto;
`;

const SCardTitle = styled(CardTitle)`
  font-size: 15px;
  padding-bottom: 8px;
`;

const PriceItem = styled.div<{ active?: boolean }>`
  border: 1px solid var(--color-border);
  border-radius: 5px;
  padding: 15px;
  color: var(--color-content-grey);
  font-size: 16px;
  margin-top: 15px;
  cursor: pointer;
  position: relative;

  ${(props) => {
        return props.active
            ? `
    border-color: var(--color-primary);
    color: var(--color-primary);
    box-shadow: inset 0 0 2px var(--color-primary);
    `
            : `

    `;
    }}
  svg {
    font-size: 22px;
    width: 22px;
    height: 22px;
    color: #ccc;
    ${(props) => {
        return props.active
            ? `
        color: var(--color-primary);
        `
            : ``;
    }}
  }
`;

const SaveBadge = styled.div<{ active?: boolean }>`
  color: white;
  padding: 2px 6px;
  border-radius: 3px;
  font-size: 12px;
  background-color: #ccc;

  ${(props) => {
        return props.active
            ? `
    background-color: var(--color-primary);
    `
            : ``;
    }}
`;



const TermsLink = "https://www.fanlist.com/terms/";
const PrivacyLink = "https://www.fanlist.com/privacy/";

const PurchaseMembershipForm = ({
    amount,
    showId,
    tierId,
    onSuccess,
    onError,
}) => {
    const [isConfirmPaymentBusy, setIsConfirmPaymentBusy] = useState(false);
    const stripe = useStripe();
    const elements = useElements();
    const [cardDetailsComplete, setCardDetailsComplete] = useState(false);
    const [cardDetailsError, setCardDetailsError] = useState(null);
    const [stripeCardIncompleteError, setStripeCardIncompleteError] =
        useState(false);
    const [isInChangeCard, setIsInChangeCard] = useState(false);
    const pAmount = useMemo(() => amount, [amount]);

    const { data: supportingPaymentMethodData, loading } = useQuery(
        GET_SUPPORTING_PAYMENT_METHOD,
        {
            fetchPolicy: "network-only",
        }
    );

    const supportingPaymentMethod = useMemo(
        () => supportingPaymentMethodData?.getSupportingPaymentMethod,
        [supportingPaymentMethodData]
    );

    const { brand, last4 } = useMemo(
        () => supportingPaymentMethod || {},
        [supportingPaymentMethod]
    );

    const hasSavedCard = useMemo(() => !!last4, [last4]);
    const isInSavedCard = useMemo(
        () => hasSavedCard && !isInChangeCard,
        [hasSavedCard, isInChangeCard]
    );

    const [stripeApiError, setStripeApiError] = useState(null);
    const [form] = Form.useForm();
    const [isTokenBusy, setIsTokenBusy] = useState(false);

    const handleAfterSuccess = () => {
        onSuccess && onSuccess();

        setIsConfirmPaymentBusy(false);
    };

    const [triggerAccessPayment, { loading: isAccessPaymentBusy }] = useMutation(
        ACCESS_MEMBERSHIP_PAYMENT_MUTATION,
        {
            onCompleted({ accessPayment: { error, success } }) {
                if (success === TRUE) {
                    setIsConfirmPaymentBusy(true);
                    handleAfterSuccess();
                } else {
                    setStripeApiError({ message: error });
                }
            },
            onError(errorPayload) { },
        }
    );

    const handleFinish = async ({ }) => {
        try {
            if (isInSavedCard) {
                await triggerAccessPayment({
                    variables: {
                        showId,
                        tierId,
                    },
                });
                return;
            }

            if (!cardDetailsComplete) {
                !cardDetailsError && setStripeCardIncompleteError(true);
                return;
            }

            const cardElement = elements.getElement(CardElement);

            setIsTokenBusy(true);
            const {
                token: { id: tokenId },
            } = await stripe.createToken(cardElement);

            setIsTokenBusy(false);

            await triggerAccessPayment({
                variables: {
                    showId,
                    tierId,
                    token: tokenId,
                },
            });
        } catch (e) {
            onError && onError();
        }
    };

    const handleCardDetailsChange = (event) => {
        setStripeApiError(null);
        setStripeCardIncompleteError(false);
        setCardDetailsComplete(event.complete);
        setCardDetailsError(event.error);
    };

    return (
        <Form onFinish={handleFinish} form={form}>
            {loading && <Skeleton />}
            {!loading && isInSavedCard && (
                <SFormItemUIOnly>
                    <SCardTitle>Payment Info</SCardTitle>
                    <SavedCardRow>
                        <Row align="middle">
                            <Col>
                                <VisaIconContainer>
                                    <VisaIcon />
                                </VisaIconContainer>
                            </Col>
                            <Col>
                                <CardNumberInfo>
                                    {brand} xxxx {last4}
                                </CardNumberInfo>
                            </Col>
                            <Spacer />
                            <Col>
                                <A onClick={() => setIsInChangeCard(true)}>change card</A>
                            </Col>
                        </Row>
                    </SavedCardRow>
                    {stripeApiError && (
                        <FormItemError>{stripeApiError.message}</FormItemError>
                    )}
                </SFormItemUIOnly>
            )}
            {!loading && !isInSavedCard && (
                <SFormItemUIOnly>
                    <SCardTitle>Payment Info</SCardTitle>
                    <CardDetails onChange={handleCardDetailsChange} />
                    {stripeApiError && (
                        <FormItemError>{stripeApiError.message}</FormItemError>
                    )}
                    {stripeCardIncompleteError && (
                        <FormItemError>All fields are required</FormItemError>
                    )}
                </SFormItemUIOnly>
            )}
            <SDivider />
            <Button
                disabled={!pAmount || amount * 1 == 0}
                htmlType="submit"
                block
                loading={
                    loading || isTokenBusy || isConfirmPaymentBusy || isAccessPaymentBusy
                }
            >
                purchase subscription
            </Button>
            <CardDescription
                css={`
          margin-top: 15px;
          text-align: center;
          color: var(--color-content-grey);
        `}
            >
                Your monthly or yearly subscription auto-renews on your selected term
                interval, until you cancel your subscription. By subscribing, you agree to
                our{" "}
                <A href={TermsLink} target="_blank">
                    terms
                </A>{" "}
                &{" "}
                <A href={PrivacyLink} target="_blank">
                    privacy policy
                </A>
                .
            </CardDescription>
        </Form>
    );
};

const BecomeMember = ({
    show,
    refetchShowQuery,
}) => {
    const { isLoggedIn, openAuthModal } = useContext(PublicPageContext);
    const [selectedTierId, setSelectedTierId] = useState(null);
    const { membership, refetch } = useContext(FanPageContext);

    const activeTiers = useMemo(
        () =>
            show.activeTiers
                ?.filter((tier: { kind: string }) => tier.kind !== "gifted")
                ?.sort((a, b) => {
                    if (a.kind === "monthly") return -1;
                    else if (a.kind === "yearly") return 0;
                    else return 1;
                }) || [],
        [show]
    );
    const selectedTier = activeTiers.find(t => t.id === selectedTierId);
    const selectedAmount = useMemo(
        () =>
            activeTiers.find((tier: { kind: any }) => tier.kind === selectedTier?.kind)
                ?.amountCents || 0,
        [selectedTier]
    );
    const savePercent = useMemo(() => {
        const monthlyAmount =
            activeTiers.find((tier: { kind: string }) => tier.kind === "monthly")
                ?.amountCents || 500;
        const yearlyAmount =
            activeTiers.find((tier: { kind: string }) => tier.kind === "yearly")
                ?.amountCents || 5000;
        return Math.round(
            ((monthlyAmount * 12 - yearlyAmount) / (monthlyAmount * 12)) * 100
        );
    }, [activeTiers]);
    const [descriptionExpanded, setDescriptionExpanded] = useState(false);

    const handleError = () => {
        // @ts-ignore
        message.error({ content: "Something went wrong, please try again later." });
    };

    const handleSuccess = () => {
        message.success("Subscription Started");
        refetchShowQuery && refetchShowQuery();
    };

    const handleSelectTier = (tier) => {
        if (tier.id === selectedTierId) {
            setSelectedTierId(null);
        } else {
            setSelectedTierId(tier.id);
            if (!isLoggedIn) {
                openAuthModal()
            }
        }
    };

    const handleAuthModal = (flag) => {
        openAuthModal(flag);
    };

    useEffect(() => {
        return () => {
            refetchShowQuery && refetchShowQuery();
            refetch()
        };
    }, [isLoggedIn]);

    return (
        <div className="BecomeMember">
            <CardHeader>
                <CardDescription ignoreLineBreak={!descriptionExpanded}>
                    <Paragraph
                        ellipsis={{
                            rows: 4,
                            expandable: true,
                            symbol: "more",
                            onExpand: () => setDescriptionExpanded(true),
                        }}
                        className="mb-0"
                        css={`color: var(--color-content-grey);`}
                    >
                        {show?.membershipDescription}
                    </Paragraph>
                </CardDescription>
            </CardHeader>
            <CardInner>
                <CardTitle style={{fontSize:'16px'}}>Payment Options</CardTitle>
                {activeTiers.map(
                    (tier: {
                        id: React.Key;
                        kind: string;
                        amountCents: number;
                    }) => {
                        return (
                            <PriceItem
                                key={tier.id}
                                active={selectedTier?.kind === tier.kind}
                                onClick={() => handleSelectTier(tier)}
                            >
                                <Row gutter={12} align="middle">
                                    <Col
                                        css={`display: flex; align-items: center;`}
                                    >
                                        <FaCheckSquare />
                                    </Col>
                                    <Col
                                        css={`text-transform: capitalize;`}
                                        flex={`1 0 0`}
                                    >
                                        {tier.kind === "one_time" ? "One-Time" : tier.kind}
                                    </Col>
                                    {tier.kind === "yearly" && (
                                        <Col>
                                            <SaveBadge active={selectedTier?.kind === tier.kind}>
                                                save {savePercent}%
                                            </SaveBadge>
                                        </Col>
                                    )}
                                    {supportUnits[tier.kind] && (
                                        <Col>
                                            {/*@ts-ignore*/}
                                            <Amount size={`14`} css={'font-weight: 500;'} color="green" inline>
                                                ${Math.round(tier.amountCents / 100)}
                                            </Amount>
                                            /{supportUnits[tier.kind]}
                                        </Col>
                                    )}
                                </Row>
                            </PriceItem>
                        );
                    }
                )}
            </CardInner>
            {isLoggedIn ? (
                <OneCollapse open={!!selectedTier}>
                    <CardInner>
                        <CardTitle
                            css={`
                      margin-bottom: 15px;
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                    `}
                        >
                            <span>Due Today</span>
                            {/*@ts-ignore*/}
                            <Amount size={`16`} css={'font-weight: 500;'} color="green" inline>
                                ${Math.round(selectedAmount / 100)}
                            </Amount>
                        </CardTitle>
                        <ElementsProvider>
                            <PurchaseMembershipForm
                                amount={selectedAmount}
                                showId={show.id}
                                tierId={selectedTierId}
                                onSuccess={handleSuccess}
                                onError={handleError}
                            />
                        </ElementsProvider>
                    </CardInner>
                </OneCollapse>
            ) : (
                <CardInner>
                    <Row gutter={16}>
                        <Col flex={`1 0 0`}>
                            <Button
                                block
                                radius={`3px`}
                                type={`default`}
                                onClick={() => {
                                    handleAuthModal(LOGIN);
                                }}
                            >
                                {LOGIN}
                            </Button>
                        </Col>
                        <Col flex={`1 0 0`}>
                            <Button
                                block
                                radius={`3px`}
                                onClick={() => {
                                    handleAuthModal(SIGNUP);
                                }}
                            >
                                {SIGNUP}
                            </Button>
                        </Col>
                    </Row>
                </CardInner>
            )}
        </div>
    );
};

export default BecomeMember;
