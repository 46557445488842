import React, { useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import { withPublicPageState } from "apps/PublicPage/context";
import {
  PUBLIC_PERKS_QUERY,
} from "apps/PublicPage/graphql";

import PerkList from "apps/PublicPage/components/PerkList";
import { SectionHeader, SectionTitle } from "apps/PublicPage/components/FanPage";
import {
  Card,
  CardInner,
  NoContentCard,
  Skeleton
} from "atoms";
import { PageInner } from "apps/PublicPage/atoms";
import styled from "styled-components";
import { useQuery } from "@apollo/client";
import { SShow } from "apps/PublicPage/containers/FanPageContainer";

const SPerkCard = styled(Card)`
  padding: 20px 25px;
`;

const ActivePerks = (
  {
    show,
    publicPageState: { isLoggedIn,  me },
  }
) => {
  // @ts-ignore
  const { slug } = useParams<{ slug: string }>();

  const {
    data: perksData,
    refetch,
    loading: loadingPerks,
  } = useQuery(PUBLIC_PERKS_QUERY, {
    variables: {
      showSlug: slug,
    },
  });
  const perks = useMemo(() => perksData?.publicPerks || [], [perksData]);

  const hasPerks = useMemo(() => perks.length > 0, [perks]);

  const refetchPerks = useCallback(() => {
    return refetch();
  }, [refetch]);

  if (loadingPerks) {
    return (
      <SShow>
        <PageInner>
          <Skeleton />
        </PageInner>
      </SShow>
    );
  }

  return (
    <>
      <SectionHeader css="">
        <SectionTitle>Featured Items</SectionTitle>
      </SectionHeader>
      <div>
        {hasPerks ? (
          <PerkList
            perks={perks}
            isLoggedIn={isLoggedIn}
            superfansPluralName={show.superfansPluralName}
            superfansSingularName={show.superfansSingularName}
            onPerkCreate={refetchPerks}
            showPurchasedFansOnPerk={show.showPurchasedFansOnPerk}
            showPurchasesNumberOnPerk={show.showPurchasesNumberOnPerk}
            show_slug={show.slug}
            me={me}
          />
        ) : (
          <NoContentCard shadow>
            Please check back. We'll have some perk soon!
          </NoContentCard>
        )}
      </div>
    </>
  )
}

export default withPublicPageState(ActivePerks);