import { useQuery } from "@apollo/client";
import { Card, CardInner, SkeletonCard } from "atoms";
import { PINNED_CHAT_MESSAGE } from "graphql-api";
import React, { useContext } from "react";
import ChatMessageItem from "./ChatMessageItem";
import { FanPageContext } from "apps/PublicPage/context";

const PinnedMesageList = () => {

  const { show } = useContext(FanPageContext);
  const { data, loading } = useQuery(PINNED_CHAT_MESSAGE, {
    variables: {
      showId: show.id
    }
  });
  const pinnedMessage = data?.pinnedChatMessage;

  if (loading) {
    return (
      <SkeletonCard />
    )
  }

  if (!pinnedMessage) {
    return null
  }

  return (
    <div className="PinnedMessageList">
      <Card shadow simple>
        <CardInner>
          <ChatMessageItem message={pinnedMessage} type="pinned" />
        </CardInner>
      </Card>
    </div>
  )
}

export default PinnedMesageList;
