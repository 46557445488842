import React, { memo, useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { A, Avatar, Col, Div, message, Popover, PopoverInner, Row, Spacer } from 'atoms';
import { Avatar as AndtAvatar, Button, MenuProps, Dropdown, ConfigProvider, Tag } from 'antd'

import { ChatMessage, User } from 'types';
import { useMemo } from 'react';
import { parseDate } from 'utils/Datetime';
import { FaEllipsisV, FaTrashAlt } from 'icons';
import { useMutation } from '@apollo/client';
import { DELETE_CHAT_MESSAGE_MUTATION, PINNED_CHAT_MESSAGE, TOGGLE_CHAT_MESSAGE_LIKE_MUTATION, TOGGLE_CHAT_MESSAGE_PIN_MUTATION } from 'graphql-api';
import { TRUE } from 'consts';
import { DeleteCta } from 'molecules';
import UserProfile from 'components/UserProfile';
import { FanPageContext, PublicPageContext } from 'apps/PublicPage/context';
import { createPortal } from 'react-dom';
import { LazyUserProfile, MessageText } from './common';

const MessageBody = styled.div``;
const MessageHeader = styled.div`
  margin-bottom: 4px;
`;

const CreatedAt = styled.div`
  font-size: 13px;
  color: var(--color-grey-lighter);
  white-space: pre;
`;

const AvatarContainer = styled.div`
  padding-top: 6px;
`;

const LikesCount = styled.div`
  font-weight: 500;
  line-height: 1;
  user-select: none;
`;
const LikeUserItem = styled.div`
  margin-bottom: 3px;
  &:last-child {
    margin-bottom: 0;
  }
`;
const ControlButton = styled(Button)`
  color: grey;
  &&& > .ant-btn-icon {
    vertical-align: middle;
    margin-inline-end: 4px;
  }
`
ControlButton.defaultProps = { type: 'link' };

type Props = {
  message: ChatMessage
}

const ChatMessageItem = (props: Props) => {
  // @ts-ignore
  const { me } = useContext(PublicPageContext);
  const { show } = useContext(FanPageContext);
  const viewerIsAdmin = show.viewerIsAdmin;
  const { message: chatMessage } = props;

  const { id, content, user, createdAt, roleLabel } = chatMessage;
  const theCreatedAt = useMemo(() => {
    const date = new Date(createdAt);
    const parsed = parseDate(date);;
    return `${parsed.hh}:${parsed.mm} ${parsed.apm}`;
  }, [createdAt]);
  const canEditMessage = viewerIsAdmin || me?.id === user.id;
  const theContent = useMemo(() => {
    // @ts-ignore
    return content.replaceAll(/@\w[^\s]+/g, (match, p1) => `<span class="mention_user">${match}</span>`).replaceAll(/@{([^|]+)\|(\d+)}/g, (_, name, id) => {
      return `<span class="mention_user MentionUser" contact-id="${id}" username="${name}"></span>`;
    });
  }, [content]);
  const textRef = useRef<HTMLDivElement>();
  const [mentionUserRef, setMentionUserRef] = useState<NodeListOf<HTMLDivElement>>();
  
  const [triggerDelete, { loading }] = useMutation(DELETE_CHAT_MESSAGE_MUTATION, {
    variables: { chatMessageId: id },
    onCompleted({ deleteChatMessage: { success } }) {
      if (success === TRUE) {
        message.success({ content: "Message Deleted" });
      }
    }
  });

  const controlItems: MenuProps['items'] = [
    {
      key: 'delete',
      label: <DeleteCta onDelete={triggerDelete} loading={loading} closeAfterDelete={true}>delete</DeleteCta>,
      icon: <FaTrashAlt verticalMiddle />,
    }
  ];
  const renderRoleTag = () => {
    switch (roleLabel) {
      case 'creator':
        return <Tag color='green'>creator</Tag>;
      case 'admin':
        return <Tag color='green'>admin</Tag>
      case 'member':
        return <Tag color='red'>subscriber</Tag>
      default:
        return null;
    }
  }
  useEffect(() => {
    setMentionUserRef(textRef.current.querySelectorAll('.MentionUser'));
  }, []);
  return (
    <div className='ChatMessageItem'>
      <Row gutter={12}>
        <Col>
          <Popover content={<UserProfile user={user} />} simple trigger={['click']} placement='topLeft'>
            <AvatarContainer>
              <Avatar src={user.avatarUrl} width="35px" radius='5px' />
            </AvatarContainer>
          </Popover>
        </Col>
        <Col flex="1 0 0" minZero>
          <MessageBody>
            <MessageHeader>
              <Row align='middle' gutter={12} css='flex-wrap: nowrap;'>
                <Col minZero css='flex-shrink: 1; overflow: hidden; text-overflow: ellipsis; white-space: pre;'>
                  <Popover content={<UserProfile user={user} />} simple trigger={['click']} placement='topLeft'>
                    <A>
                      {user.displayName}
                    </A>
                  </Popover>
                </Col>
                {
                  roleLabel !== 'fan' && (
                    <Col>{renderRoleTag()}</Col>
                  )
                }
                <Col>
                  <CreatedAt>{theCreatedAt}</CreatedAt>
                </Col>
                <Spacer />
                {
                  canEditMessage && (
                    <Col>
                      <Dropdown trigger={["click"]} menu={{items: controlItems}}>
                        <Button icon={<FaEllipsisV />} type="text" />
                      </Dropdown>
                    </Col>
                  )
                }
              </Row>
            </MessageHeader>
            <MessageText ref={textRef} dangerouslySetInnerHTML={{ __html: theContent }}></MessageText>
          </MessageBody>
        </Col>
      </Row>
      {/* this is for profile popup for user mentioned in message text */}
      {mentionUserRef && [...mentionUserRef].map((el) => (
        createPortal(
          <Popover content={<LazyUserProfile contactId={el.getAttribute('contact-id')} />} simple trigger={['click']} placement='topLeft'>
            @{el.getAttribute('username')}
          </Popover>,
          el
        )
      ))}
    </div>
  )
}

export default memo(ChatMessageItem);
