import React, { useContext, useMemo } from "react";
import styled from "styled-components";
import { Row, Col, A, Div, CardTitle } from "atoms";
import { supportUnits } from "micro-data";
import CopyClipboard from "components/CopyClipboard";
import { Flex } from "antd";
import { FaCopy } from "icons";
import { DashboardContext } from "apps/Dashboard/context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRssSquare } from "@fortawesome/free-solid-svg-icons";

const SMembershipTab = styled.div``;

const MembershipTab = ({ membership }) => {
  const { show } = useContext(DashboardContext);
  const cancelledStatus = {
    cancel_pending: "cancel pending",
    past_due: "past due",
    canceled: "canceled",
  };
  const isMember = !['past_due', 'canceled'].includes(membership?.status)
  const rssLink = `${window.location.origin}/rss/${membership?.uuid}.xml`;

  return (
    <SMembershipTab>
      {membership ? (
        <Div color={`var(--color-content-grey)`}>
          <Row
            css={`
              margin-bottom: 10px;
            `}
          >
            <Col flex={`1 0 0`}>Status</Col>
            <Col>
              {cancelledStatus[membership.status] ? (
                <A>{cancelledStatus[membership.status]}</A>
              ) : (
                <Div color={`green`}>{membership.status}</Div>
              )}
            </Col>
          </Row>
          <Row
            css={`
              margin-bottom: 10px;
            `}
          >
            <Col flex={`1 0 0`}>Subscription</Col>
            <Col>
              {supportUnits[membership.tier.kind] ? (
                <>
                  ${Math.round(membership.amountCents / 100)} /
                  {supportUnits[membership.tier.kind]}
                </>
              ) : (
                <>gifted</>
              )}
            </Col>
          </Row>
          <Row
            css={`
              margin-bottom: 10px;
            `}
          >
            <Col flex={`1 0 0`}>Join Date</Col>
            <Col>{membership.createdAtFormatted}</Col>
          </Row>
          <Row>
            <Col flex={`1 0 0`}>End Date</Col>
            <Col>
              {membership.canceledAt
                ? membership.canceledAtFormatted
                : membership.cancelRequestedAt
                  ? membership.nextBillAtFormatted
                  : "--"}
            </Col>
          </Row>
          {
            isMember && show.podcast && (
              <div className="mt-3">
                <Flex align="center" gap={8} className="mb-1">
                  <FontAwesomeIcon icon={faRssSquare} color="#e15a0c" fontSize="18px" />
                  <CardTitle>RSS Feed</CardTitle>
                </Flex>
                <CopyClipboard copyContent={rssLink}>
                  <Flex align="center" gap={10}>
                    <span style={{ flex: '1 0 0', minWidth: 0, overflow: "hidden", textOverflow: "ellipsis", whiteSpace: 'pre' }}>{rssLink}</span>
                    <FaCopy />
                  </Flex>
                </CopyClipboard>
              </div>
            )
          }
        </Div>
      ) : (
        <Div color={`var(--color-content-grey)`}>
          <Row
            css={`
              margin-bottom: 10px;
            `}
          >
            <Col flex={`1 0 0`}>Status</Col>
            <Col
              css={`
                color: #ddd;
              `}
            >
              Inactive
            </Col>
          </Row>
          <Row
            css={`
              margin-bottom: 10px;
            `}
          >
            <Col flex={`1 0 0`}>Subscription</Col>
            <Col>--</Col>
          </Row>
          <Row
            css={`
              margin-bottom: 10px;
            `}
          >
            <Col flex={`1 0 0`}>Join Date</Col>
            <Col>--</Col>
          </Row>
          <Row>
            <Col flex={`1 0 0`}>End Date</Col>
            <Col>--</Col>
          </Row>
        </Div>
      )}
    </SMembershipTab>
  );
};

export default MembershipTab;
