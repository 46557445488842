import React, { useContext } from 'react';
import styled from 'styled-components';
import { useMutation, useQuery } from '@apollo/client';
import { CREATE_CHAT_MESSAGE_MUTATION } from 'graphql-api';
import { Show, ChatContact, Contact } from 'types';
import { Avatar, Button, Col, Div, Form, FormItem, Row, Span } from 'atoms';
import { FaPaperPlane } from 'icons';
import { useState } from 'react';
import { useMemo } from 'react';
import { FanPageContext, PublicPageContext } from 'apps/PublicPage/context';
import { PUBLIC_SHOW_CHAT_CONTACTS_QUERY } from 'graphql-api/chat';
import { SMentions } from './common';

const { Option } = SMentions;

const SChatForm = styled.div``;

type ChatContactItemProps = {
  chatContact: ChatContact
}
const ChatContactItem = (props: ChatContactItemProps) => {

  const {chatContact: {avatarUrl, displayName}} = props;

  return (
    <Div>
      <Row align="middle" gutter={10}>
        <Col>
          <Avatar asBackground src={avatarUrl} width='35px'/>
        </Col>
        <Col>
          <Span>{displayName}</Span>
        </Col>
      </Row>
    </Div>
  );
}
const SendButton = styled(({active, ...props}) => <Button {...props} />)`
  width: 45px !important;
  height: 45px;
  min-width: 45px;

  &.ant-btn-icon-only {
    min-width: 45px;
  }

  ${props => props.active ? `
  ` : `
      --color: #f8f8f8;
      --color-hover: #f8f8f8;
      color: #a4a4a4;
      :hover, :focus, :active {
        color: #a4a4a4;
      }
      &:not(:hover) {
        border-color: #eeeeee;
      }
  `}
`;

type Props = {
  onSubmit: () => void
};
const ChatForm = (props: Props) => {

  const { isLoggedIn, openAuthModal } = useContext(PublicPageContext);
  const { show } = useContext(FanPageContext);
  const { data: publicChatContactsData } = useQuery(
    PUBLIC_SHOW_CHAT_CONTACTS_QUERY,
    {
      variables: {
        showId: show.id,
      },
    }
  );
  const chatContacts = useMemo(
    () => publicChatContactsData?.publicShowChatContacts || [],
    [publicChatContactsData]
  );
  const {viewerSuspendedPosting} = show;
  const [form] = Form.useForm();
  const [inputText, setInputText] = useState('');
  const canSendText = useMemo(() => !!inputText, [inputText]);
  const autoSize = useMemo(() => ({maxRows: 5}), []);
  const [optionMap, setOptionMap] = useState({});
  const [triggerSendMessage, {loading: isSendBusy}] = useMutation(CREATE_CHAT_MESSAGE_MUTATION, {
    onCompleted() {
      props.onSubmit()
    },
  })

  const handleFinish = async ({messageText}) => {
    if (!isLoggedIn) {
      openAuthModal();
      return false;
    }
    if (!messageText) return false;
    form.resetFields(['messageText']);
    setInputText('');
    const input = form.getFieldInstance('messageText');
    input.textarea.focus();
    const formattedMessageText = messageText.replaceAll(/@\w+/g, (match: string) => {
      const v = match.substring(1);
      if (v in optionMap) {
        const option = optionMap[v]
        return `@{${option.displayName}|${option.id}}`
      }
      return match;
    });
    await triggerSendMessage({
      variables: {
        content: formattedMessageText,
        showId: show.id
      }
    })
  }

  const handleEnter = (e) => {
    if (!e.shiftKey) {
      e.preventDefault();
      e.stopPropagation();
      form.submit();
      return false;
    }
  }
  const handleChange = (text) => {
    setInputText(text);
  }
  const handleSelectOption = (option) => {
    setOptionMap({
      ...optionMap,
      [option.value]: chatContacts.find(c => c.id.toString() === option.key)
    });
  }
  return (
    <SChatForm>
      <Form
        form={form}
        onFinish={handleFinish}
        initialValues={{
          messageText: ''
        }}
      >
        <Row gutter={6}>
          <Col flex='1 0 0'>
            <FormItem name="messageText"
              rules={[
                {
                  max: 1500,
                  message: 'Message cannot be greater than 1500 characters'
                }
              ]}
            >
              <SMentions
                rows={1}
                autoSize={autoSize}
                placement="top"
                disabled={viewerSuspendedPosting}
                placeholder={viewerSuspendedPosting ? 'your chat privileges are currently on hold' : 'post a message'}
                onPressEnter={handleEnter}
                onChange={handleChange}
                onSelect={handleSelectOption}
              >
                {
                  chatContacts && chatContacts.map(chatContact => chatContact && (
                    <Option
                      key={chatContact.id.toString()}
                      value={chatContact.displayName?.replace(' ', '') || ''} // TODO: need to fill displayName for all contacts in database level
                    >
                      <ChatContactItem chatContact={chatContact}/>
                    </Option>
                  ))
                }
              </SMentions>
            </FormItem>
          </Col>
          <Col>
            <SendButton
              block
              htmlType='submit'
              shape="circle"
              active={canSendText}
              disabled={viewerSuspendedPosting}
              icon={<FaPaperPlane/>}
            />
          </Col>
        </Row>
      </Form>
    </SChatForm>
  )
};

export default ChatForm;