import React from 'react';
import styled from 'styled-components';

import { ChatMessage, User } from 'types';
import { useMemo } from 'react';
import { MessageText } from './common';

export const SWelcomeMessageItem = styled.div`
  ${MessageText} {
    background-color: rgb(246, 255, 250);
    border-color: var(--color-green);
  }
`;

export const SWarningMessageItem = styled.div`
  ${MessageText} {
    background-color: var(--color-alert-yellow);
    border-color: var(--color-alert-yellow-dark);
    margin-bottom: 20px;
  }
`;

type Props = {
  message: ChatMessage,
  me: User,
  viewerIsAdmin: boolean
}

const WelcomeMessageItem = (props: Props) => {

  const { message: { id, content }, me, viewerIsAdmin } = props;
  const theContent = useMemo(() => {
    // @ts-ignore
    return content.replaceAll(/@\w[^\s]+/g, (match, p1) => `<span class="mention_user">${match}</span>`);
  }, [content]);

  return (
    <SWelcomeMessageItem>
      <MessageText dangerouslySetInnerHTML={{ __html: theContent }}></MessageText>
    </SWelcomeMessageItem>
  )
}

export default WelcomeMessageItem;
