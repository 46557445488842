import React, { useContext, useEffect, useMemo, useState } from 'react';
import styled, { withTheme } from 'styled-components';
import { useQuery, useLazyQuery } from '@apollo/client';
import { ME_QUERY, NOTIFICATIONS_QUERY, ME_UNSEEN_NOTIFICATIONS_COUNT_QUERY } from 'graphql-api';
import { useHistory } from 'react-router-dom';

import { Avatar, Button, Col, Popover, Row, Badge, Skeleton, Drawer } from 'atoms';
import { FaBell, FaTimes } from 'icons';
import { DashboardContext } from '../context';
import { FanpageContext } from '../../../Fanpage/context';
import MemberDetailsContainer from './Membership/MemberDetails';

const S = {};

S.NotificationButton = styled(({ hasUnseenNotifications, ...props }) => <Button {...props} />)`
  border: none;
  width: 40px;
  height: 40px;
  box-shadow: ${props => props.dashboard ? '0 0 10px 0 rgba(0, 0, 0, 0.05)' : 'rgb(0 0 0 / 15%) 0px 0px 10px 0px'};
  color: rgb(232, 232, 232);

  ${props => props.hasUnseenNotifications && `
        color: #FFCC00;
     ` }
  &.ant-btn-icon-only {
    width: 40px;
  }
`;


export const NotificationButton = ({ hasUnseenNotifications, ...props }) => {
  return (
    <S.NotificationButton
      hasUnseenNotifications={hasUnseenNotifications}
      icon={<FaBell css={`width: 14px;
        height: 16px;` } />}
      type={'default'}
      shape="circle"
      className={`ant-btn-icon-only`}
      {...props}
    >

    </S.NotificationButton>
  )
};

S.NotificationItem = styled.div`
  padding: 10px 20px;
  cursor: pointer;
  ${props => !props.seen && `
        background-color: var(--color-pink-light);
    ` }
`;
S.Avatar = styled(Avatar)`
`;
S.NotificationBody = styled.div``;
S.NotificationAgo = styled.div`
  font-size: 12px;
  color: var(--color-grey-lighter);
`;
S.NotificationContent = styled.div`
  font-size: 13px;
  max-width: 258px;
`;

const NotificationItem = ({ notification, onLink, onAvatarClick, allowClick }) => {
  const { seen, sender: { avatarUrl, id }, sentAgo, notificationText } = notification;
  const history = useHistory();
  const handleClickContent = (event) => {
    if (!allowClick)
      return;

    event.preventDefault()

    if (event.target.tagName === 'A') {
      const href = event.target.getAttribute('href');
      const origin = location.origin;
      if (href.slice(origin.length) && href.slice(0, origin.length) === origin) {
        onLink && onLink();
        history.push(href.slice(origin.length));
      } else {
        onLink && onLink();
        window.open(href, '_blank');
      }
    }
  }
  return (
    <S.NotificationItem seen={seen} style={{ cursor: allowClick ? 'pointer' : 'default'}}>
      <Row gutter={16} singleRow align={`middle`}>
        <Col>
          <div onClick={() => allowClick && onAvatarClick && onAvatarClick(id)}>
            <S.Avatar src={avatarUrl} width={`40px`} />
          </div>
        </Col>
        <Col flex={`1 0 0`}>
          <S.NotificationBody>
            <S.NotificationAgo>{sentAgo} ago</S.NotificationAgo>
            <S.NotificationContent onClick={allowClick ? handleClickContent : undefined}>
              {allowClick ? (
                <div dangerouslySetInnerHTML={{ __html: notificationText }} />
              ) : (
                <div>{notificationText.replace(/<\/?[^>]+(>|$)/g, "")}</div>
              )}
            </S.NotificationContent>
          </S.NotificationBody>
        </Col>
      </Row>
    </S.NotificationItem>
  );
}

S.Notifications = styled.div`
  width: 360px;
  max-width: 100vw;
`;
S.Header = styled.header`
  padding: 10px 20px;
  border-bottom: 1px solid var(--color-border);
`;
S.Title = styled.div`
  font-size: 13px;
`;
S.NotificationList = styled.div`
  min-height: 150px;
  max-height: 422px;
  overflow: auto;
`;
S.ItemContainer = styled.div`
  border-bottom: 1px solid var(--color-border);

  :last-child {
    border-bottom: none;
  }
`;
const SkeletonContainer = styled.div`
  padding: 10px 20px;
`;

S.NoContent = styled.div`
  padding: 10px 20px 20px;
`;

export const Notifications = ({ onLink, selectedUser, setSelectedUser, fanPageShow, dashboard }) => {
  const { show } = useContext(DashboardContext);
  let showId = show?.id;
  if (fanPageShow) {
    showId = fanPageShow;
  }
  const { data, loading } = useQuery(NOTIFICATIONS_QUERY, {
    fetchPolicy: 'network-only',
    variables: {
      showId,
      dashboard,
    }
  });
  const notifications = useMemo(() => data?.notifications || [], [data]);
  const [triggerMeQuery] = useLazyQuery(ME_QUERY, {
    fetchPolicy: `network-only`
  });
  const handleAvatarClick = (id) => {
    setSelectedUser(id);
  }
  useEffect(() => {
    if (!loading) {
      triggerMeQuery();
    }
  }, [loading])

  return (
    <S.Notifications>
      <S.Header>
        <S.Title>
          Notifications
        </S.Title>
      </S.Header>
      {
        loading && (
          <SkeletonContainer>
            <Skeleton avatar active paragraph={{ rows: 2 }} />
          </SkeletonContainer>
        )
      }
      {
        !loading && notifications.length > 0 && (
          <S.NotificationList>
            {
              notifications.map(notification => (
                <S.ItemContainer key={notification.id}>
                  <NotificationItem notification={notification} onLink={onLink} allowClick={dashboard} onAvatarClick={(id) => notification.senderIsFan && handleAvatarClick(id)} />
                </S.ItemContainer>
              ))
            }
          </S.NotificationList>
        )
      }
      {
        !loading && notifications.length === 0 && (
          <S.NoContent>
            You have no notifications
          </S.NoContent>
        )
      }
      <Drawer
        title="Fan Details"
        styles={{
          body: {
            padding: 0,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }
        }}
        contentWrapperStyle={{ maxWidth: "100%" }}
        placement={"right"}
        closable={true}
        onClose={() => setSelectedUser(null)}
        open={!!selectedUser}
        width={450}
        closeIcon={<FaTimes />}
        mask
        zIndex={1050}
        destroyOnClose
        simple
      >
        {
          !!selectedUser && (
            <MemberDetailsContainer
              userId={selectedUser}
              showId={show.id}
              onCancel={() => setSelectedUser(null)}
            />
          )
        }
      </Drawer>
    </S.Notifications>
  )
};

const NotificationsWithPopover = (
  {
    theme: { isDesktop },
    ...props
  }) => {

  const { data: unseenNotificationsCount } = useQuery(ME_UNSEEN_NOTIFICATIONS_COUNT_QUERY, {
    variables: {
      dashboard: props.dashboard,
      showId: props.fanPageShow
    }
  });
  const [visible, setVisible] = useState(false);
  const [hasUnseenNotifs, setHasUnseenNotifs] = useState(false);

  useEffect(() => {
    if (unseenNotificationsCount?.meUnseenNotificationsCount > 0) {
      setHasUnseenNotifs(true);
    }
  }, [unseenNotificationsCount]);
  const [selectedUser, setSelectedUser] = useState(null);
  const handleLink = () => {
    setVisible(false);
  }
  const handleOpenChange = (open) => {
    if (open) {
      setVisible(open);
      setHasUnseenNotifs(false);
    } else {
      if (!selectedUser) {
        setVisible(open);
      }
    }
  }
  return (
    <Popover
      placement={isDesktop ? 'bottomRight' : 'bottom'}
      arrow
      trigger={["click"]}
      content={<Notifications onLink={handleLink} selectedUser={selectedUser} setSelectedUser={setSelectedUser} fanPageShow={props.fanPageShow} dashboard={props.dashboard} />}
      simple
      destroyTooltipOnHide
      open={visible}
      onOpenChange={handleOpenChange}
      overlayInnerStyle={{ marginRight: isDesktop ? `inherit` : `20px` }}
    >
      <div style={{ lineHeight: 0 }}>
        <Badge count={0} inCircle>
          <NotificationButton hasUnseenNotifications={hasUnseenNotifs} dashboard={props.dashboard} />
        </Badge>
      </div>
    </Popover>
  )
}

export default withTheme(NotificationsWithPopover);
